import { type OpenTypeFeatureMetaData } from '../hooks/useConfig';
import { type FontFamiliesQuery } from '../gql/api-ssr';
import isEmptyHtml from './isEmptyHtml';
import isBrowserSafari from './isBrowserSafari';
import { FontFeatureSetting } from './type-editor/FontFeatureSetting';
import stripHtml from './stripHtml';
import openTypeTagsToFontFeatureSettings from './openTypeTagsToFontFeatureSettings';

const SAFARI_BUGFIX_FEATURES = [FontFeatureSetting.calt.toString()];

export default function getOpenTypeFeatureExample(
    openTypeFeature: FontFamiliesQuery['ssr']['fontFamilies'][number]['openTypeFeatures'][number],
    openTypeFeatureMetaData: OpenTypeFeatureMetaData[],
): string | undefined {
    let output: string | null | undefined = openTypeFeature.example;

    // Look up in Config
    if (output === undefined || output === null) {
        output = openTypeFeatureMetaData.find(
            (feat) => feat.tag === openTypeFeature.tag,
        )?.defaultExample;
    }
    if (output === null || (output !== undefined && isEmptyHtml(output))) {
        output = undefined;
    }

    // Safari-specific bug fix. Remove all mark tags and highlight the entire text.
    // See: https://bugs.webkit.org/show_bug.cgi?id=289997
    if (
        output !== undefined &&
        SAFARI_BUGFIX_FEATURES.includes(openTypeFeature.tag) &&
        isBrowserSafari()
    ) {
        const fontFeatureSettings = openTypeTagsToFontFeatureSettings(
            [openTypeFeature.tag, ...openTypeFeature.secondaryTags],
            false,
            "'",
        );
        output = `<p><mark style="font-feature-settings: ${fontFeatureSettings}">${stripHtml(output)}</mark></p>`;
    }

    return output;
}
