/*
 * Good reference material on `font-feature-settings`:
 *
 * https://developer.mozilla.org/en-US/docs/Web/CSS/font-feature-settings
 * https://docs.microsoft.com/en-us/typography/opentype/spec/featurelist
 */

import type React from 'react';
import type { DraftInlineStyle } from 'draft-js';
import { decompileTokenName, TokenType } from './tokens';
import arrayToCsv from '../arrayToCsv';
import openTypeTagsToFontFeatureSettings from '../openTypeTagsToFontFeatureSettings';
import valuesToFontVariationSettings from '../valuesToFontVariationSettings';

/**
 * Given an array like ['Calibre', 'Metric'], returns a valid comma-delimited
 * `font-family` CSS value, in the format:
 * "Calibre", "Metric"
 *
 * For cssProps.fontFamily.
 */
export function getCssFontFamily(fontFamilies: string[]): string {
    return arrayToCsv(fontFamilies);
}

/**
 * Returns a CSSStyleDeclaration with `font-feature-settings` property. Tells
 * Draft.js which inline styles to actually apply to any given piece of content.
 */
export default function customStyleFn(
    inlineStyles: DraftInlineStyle,
    validOpenTypeTags: string[],
): React.CSSProperties {
    const cssProps: React.CSSProperties = {};

    // Parse inline style tokens into:
    // - OpenType feature names
    // - VF axis values
    // - Font family name(s)
    const openTypeFeatures: string[] = [];
    const fontFamilies: string[] = [];
    const variableAxisValues: { tag: string; value: number }[] = [];

    // Because of the way VF are defined, we can get into a situation
    // where we try to apply the same tag multiple times. Therefore,
    // we remember the processed tags and ensure we only process it once.
    const variableTagsProcessed: string[] = [];

    inlineStyles.reverse().forEach((token) => {
        const tokenData = token ? decompileTokenName(token) : undefined;
        if (!tokenData) {
            return;
        }
        switch (tokenData.type) {
            case TokenType.OPENTYPE:
            case TokenType.OPENTYPE_NSTY:
                tokenData.names.forEach((name) => {
                    if (!validOpenTypeTags.includes(name)) {
                        return;
                    }
                    openTypeFeatures.push(name);
                });
                if (
                    tokenData.optionalName &&
                    validOpenTypeTags.includes(tokenData.optionalName)
                ) {
                    // Only add the optional tags if they appear in the list of valid tags (for the font family)
                    openTypeFeatures.push(tokenData.optionalName);
                }
                break;
            case TokenType.VARIABLE:
                if (variableTagsProcessed.includes(tokenData.names[0])) {
                    break;
                }
                variableAxisValues.push({
                    tag: tokenData.names[0],
                    value: tokenData.value || 0,
                });
                variableTagsProcessed.push(tokenData.names[0]);
                break;
            case TokenType.FAMILY:
                fontFamilies.push(...tokenData.names);
                break;
        }
    });

    // Apply to CSSProperties
    if (openTypeFeatures.length) {
        cssProps.fontFeatureSettings =
            openTypeTagsToFontFeatureSettings(openTypeFeatures);
    }
    if (variableAxisValues.length) {
        cssProps.fontVariationSettings =
            valuesToFontVariationSettings(variableAxisValues);
    }
    if (fontFamilies.length) {
        cssProps.fontFamily = getCssFontFamily(fontFamilies);
    }

    return cssProps;
}
