import React from 'react';
import { useFontFamily } from './PageContext';
import Credits from './Credits';

function FontFamilyCredits(): React.ReactElement {
    const {
        release,
        creditDesign,
        creditEngineering,
        creditCollaborators,
        classification,
        awards,
        fontFamilyGroup,
        versionNumber,
    } = useFontFamily();
    const { awards: groupAwards } = fontFamilyGroup;

    return (
        <Credits
            releases={release ? [release] : null}
            versionNumber={versionNumber}
            designers={creditDesign}
            engineers={creditEngineering}
            collaborators={creditCollaborators}
            classifications={classification}
            awards={[...groupAwards, ...awards]}
        />
    );
}

export default FontFamilyCredits;
