import { type OpenTypeFeatureMetaData } from '../hooks/useConfig';
import { type FontFamiliesQuery } from '../gql/api-ssr';
import isEmptyHtml from './isEmptyHtml';

export default function getOpenTypeFeatureAllGlyphs(
    openTypeFeature: FontFamiliesQuery['ssr']['fontFamilies'][number]['openTypeFeatures'][number],
    openTypeFeatureMetaData: OpenTypeFeatureMetaData[],
): string | undefined {
    let output: string | null | undefined = openTypeFeature.allGlyphs;

    // Look up in Config
    if (output === undefined || output === null) {
        output = openTypeFeatureMetaData.find(
            (feat) => feat.tag === openTypeFeature.tag,
        )?.defaultAllGlyphs;
    }

    if (output === null || (output !== undefined && isEmptyHtml(output))) {
        output = undefined;
    }

    return output;
}
