import React from 'react';
import styled, { css } from 'styled-components';
import { VIEWPORT } from '../settings/Global';
import {
    OPEN_TYPE_APP_SUPPORT_ADOBE_HYPERLINKS,
    OPEN_TYPE_APP_SUPPORT_AFFINITY_HYPERLINKS,
    OPEN_TYPE_APP_SUPPORT_MICROSOFT_HYPERLINKS,
    OPEN_TYPE_APP_SUPPORT_OTHER_HYPERLINKS,
} from '../settings/Hyperlinks';
import { A } from './InlineUnderlinedLink';
import { useStaticFontMetricOffsets } from './PageContext';
import type { FontMetricOffsets } from '../utils/fontMetricsCalculator';

const Wrap = styled.div<{
    $metricOffsets: FontMetricOffsets | undefined;
}>`
    display: grid;
    grid-gap: var(--gridColumnGap);
    grid-template-columns: repeat(8, minmax(0, 1fr));

    ${({ $metricOffsets }): ReturnType<typeof css> | null =>
        $metricOffsets
            ? css`
                  @media screen and (min-width: ${VIEWPORT.MOBILE + 1}px) {
                      margin-top: ${-$metricOffsets.ascent}em;
                      margin-bottom: ${$metricOffsets.descent}em;
                  }
              `
            : null};

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
        grid-row-gap: 0;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-row-gap: 0;
    }
`;

const LinkColumn = styled.div`
    grid-column-end: span 2;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column-end: span 3;
    }

    ${A} {
        display: block;

        --color: var(--foregroundColorMix6);
        --underlineColor: var(--color);
    }
`;

function FontFamilyOpenTypeFeaturesAppSupport(): React.ReactElement {
    const staticFontMetrics = useStaticFontMetricOffsets();

    return (
        <Wrap $metricOffsets={staticFontMetrics.SOEHNE_BUCH.BODY_2}>
            {[
                OPEN_TYPE_APP_SUPPORT_ADOBE_HYPERLINKS,
                OPEN_TYPE_APP_SUPPORT_AFFINITY_HYPERLINKS,
                OPEN_TYPE_APP_SUPPORT_OTHER_HYPERLINKS,
                OPEN_TYPE_APP_SUPPORT_MICROSOFT_HYPERLINKS,
            ].map((links, idx) => {
                return (
                    <LinkColumn key={`otAppLinks${idx}`}>
                        {links.map((link) => (
                            <A href={link.url} key={link.url}>
                                {link.title}
                            </A>
                        ))}
                    </LinkColumn>
                );
            })}
        </Wrap>
    );
}

export default FontFamilyOpenTypeFeaturesAppSupport;
