import React from 'react';
import { type EditorState } from 'draft-js';

import tokens, {
    compileTokenName,
    decompileTokenName,
    type Token,
    TokenType,
} from '../utils/type-editor/tokens';
import useTypeEditorUsedStyles from '../hooks/type-editor/useTypeEditorUsedStyles';
import { useFontFamily } from './PageContext';
import { useEditorState } from './TypeEditorContext';
import getInlineStylesForSelection from '../utils/type-editor/getInlineStylesForSelection';
import updateEditorInlineStyles from '../utils/type-editor/updateEditorInlineStyles';
import { Select, SelectItem, type SelectProps } from './Select';
import LozengeCheckbox, { type CheckboxProps } from './LozengeCheckbox';
import notUndefined from '../utils/notUndefined';
import useDraftJsCurrentInlineStyle from '../hooks/type-editor/useDraftJsCurrentInlineStyle';
import useDraftJsSelection from '../hooks/type-editor/useDraftJsSelection';
import { useOpenTypeFeatureMetaData } from '../hooks/useConfig';
import getOpenTypeFeatureLabel from '../utils/getOpenTypeFeatureLabel';
import notDuplicate from '../utils/notDuplicate';
import { FontFeatureSetting } from '../utils/type-editor/FontFeatureSetting';

type FeatureTokens = Token | Token[];

// This sets the features to show in the UI (if available in the specimen),
// and the order in which they are shown.
//
// If there are multiple tokens in an array, then the interface will enforce a mutually-exclusive
// selection, e.g. select list or radio buttons.
const featuresInOrder: FeatureTokens[] = [
    [
        tokens.numstyDefault,
        tokens.numstyLining,
        tokens.numstyOldstyle,
        tokens.numstyTabularLining,
        tokens.numstyTabularOldstyle,
    ],
    tokens.ss01,
    tokens.ss02,
    tokens.ss03,
    tokens.ss04,
    tokens.ss05,
    tokens.ss06,
    tokens.ss07,
    tokens.ss08,
    tokens.ss09,
    tokens.ss10,
    tokens.ss11,
    tokens.ss12,
    tokens.ss13,
    tokens.ss14,
    tokens.ss15,
    tokens.ss16,
    tokens.ss17,
    tokens.ss18,
    tokens.ss19,
    tokens.ss20,
    tokens.smcp,
    tokens.c2sc,
    tokens.dlig,
    tokens.swsh,
    tokens.zero,
    tokens.frac,
    tokens.sups,
    tokens.sinf,
    tokens.ordn,
    tokens.case,
];

export function useFeatureTagsInOrder(): string[] {
    return React.useMemo(() => {
        function getTokenTags(token: Token): (string | undefined)[] {
            return [...token.names, token.optionalName];
        }
        return featuresInOrder
            .flatMap((feat) =>
                !Array.isArray(feat)
                    ? getTokenTags(feat)
                    : feat.flatMap(getTokenTags),
            )
            .filter(notUndefined)
            .filter(notDuplicate);
    }, []);
}

type OpenTypeFeatureWithState = {
    tokens: Token[];
    selectedToken?: Token;
    indeterminate: boolean;
    disabled: boolean;
    onCheckedChange?: CheckboxProps['onCheckedChange'];
    onValueChange?: SelectProps['onValueChange'];
};

const DEFAULT_NUMERALS_NAME = compileTokenName({
    type: TokenType.OPENTYPE_NSTY,
    names: [],
});

const LINING_NUMERALS_NAME = compileTokenName({
    type: TokenType.OPENTYPE_NSTY,
    names: [FontFeatureSetting.lnum],
    optionalName: FontFeatureSetting.pnum,
});

/**
 * Returns the OpenTypeFeatures that are available for the current editor's FontStyles.
 */
export function useAvailableFeatures(): FeatureTokens[] {
    const fontFamily = useFontFamily();
    const usedStyles = useTypeEditorUsedStyles();

    // Filter the OpenType features based on those used by the styles in the editor
    const [usedFontFamilyFeatures, usedFontFamilyFeatureTags] =
        React.useMemo(() => {
            const usedFontFamilyFeatures = fontFamily.openTypeFeatures.filter(
                (feat) =>
                    // Exclude features of styles that are not used by the editor
                    feat.fontStyles.find((style) =>
                        usedStyles.includes(style.name),
                    ),
            );
            return [
                usedFontFamilyFeatures,
                usedFontFamilyFeatures.map((f) => f.tag),
            ];
        }, [fontFamily, usedStyles]);

    const filterFeatureToShow = React.useCallback(
        (featureToFilter: Token) => {
            if (
                // Always show default numerals
                [DEFAULT_NUMERALS_NAME, LINING_NUMERALS_NAME].includes(
                    featureToFilter.name,
                ) ||
                // Show it when available in `FontFamily.openTypeFeatures`
                featureToFilter.names.every((filterTag) =>
                    usedFontFamilyFeatureTags.includes(filterTag),
                )
            ) {
                return featureToFilter;
            }
        },
        [usedFontFamilyFeatures],
    );

    // Filter `featuresInOrder` based on what's available
    return React.useMemo(
        () =>
            featuresInOrder
                .map((featureToShow): FeatureTokens | undefined => {
                    if (!Array.isArray(featureToShow)) {
                        // A single token
                        return filterFeatureToShow(featureToShow);
                    }
                    // Multiple tokens, filter each
                    return featureToShow
                        .map((singleFeatureToShow) =>
                            filterFeatureToShow(singleFeatureToShow),
                        )
                        .filter(notUndefined);
                })
                .filter(notUndefined)
                // Remove potentially single selection or empty arrays
                .filter((featureToShow): boolean => {
                    return (
                        !Array.isArray(featureToShow) ||
                        featureToShow.length > 1
                    );
                }),
        [filterFeatureToShow],
    );
}

function useFeaturesWithState(): OpenTypeFeatureWithState[] {
    const availableFeatures = useAvailableFeatures();
    const openTypeFeatureMetaData = useOpenTypeFeatureMetaData();
    const [editorState, setEditorState] = useEditorState();
    const currentInlineStyle = useDraftJsCurrentInlineStyle();
    const currentSelection = useDraftJsSelection();
    const fontFamily = useFontFamily();
    const editorHasText = React.useMemo(() => {
        return editorState.getCurrentContent().hasText();
    }, [editorState]);

    const inlineStylesForSelection = React.useMemo(
        () => getInlineStylesForSelection(editorState),
        [editorState],
    );

    return availableFeatures.map((availableFeature) => {
        const isSingleToken = !Array.isArray(availableFeature);

        const inlineStyleForSelection = inlineStylesForSelection.find(
            (inlineStyleForSelection) =>
                isSingleToken
                    ? inlineStyleForSelection.style === availableFeature.name
                    : availableFeature.find(
                          (token) =>
                              token.name === inlineStyleForSelection.style,
                      ),
        );
        const cursorPositionHasToken = isSingleToken
            ? currentInlineStyle.has(availableFeature.name)
            : availableFeature.some((token) =>
                  currentInlineStyle.has(token.name),
              );
        const selectionHasToken =
            inlineStyleForSelection !== undefined ||
            (currentSelection.isCollapsed() && cursorPositionHasToken);
        const inlineStyleForSelectionIsIndeterminate =
            (inlineStyleForSelection?.indeterminate &&
                !currentSelection.isCollapsed()) ||
            false;

        const selectedToken = isSingleToken
            ? selectionHasToken
                ? availableFeature
                : undefined
            : !selectionHasToken
              ? availableFeature[0]
              : availableFeature.find(
                    (token) =>
                        (inlineStyleForSelection &&
                            inlineStyleForSelection.style === token.name) ||
                        (currentSelection.isCollapsed() &&
                            currentInlineStyle.has(token.name)),
                );

        return {
            tokens: (isSingleToken ? [availableFeature] : availableFeature)
                .map((token) => {
                    const tokenData = decompileTokenName(token.name);
                    const tag = tokenData?.names[0];
                    // Apply label
                    let label = token.label;
                    if (label === undefined) {
                        label =
                            token.name === DEFAULT_NUMERALS_NAME
                                ? tokens['numstyDefault'].label
                                : tag !== undefined
                                  ? getOpenTypeFeatureLabel(
                                        tag,
                                        openTypeFeatureMetaData,
                                        fontFamily.openTypeFeatures,
                                    )
                                  : 'OpenType feature';
                    }
                    return {
                        ...token,
                        label,
                    };
                })
                .filter(notUndefined),
            selectedToken,
            indeterminate: inlineStyleForSelectionIsIndeterminate,
            disabled: !editorHasText,
            onCheckedChange: isSingleToken
                ? (checked): void => {
                      setEditorState((state: EditorState): EditorState => {
                          return updateEditorInlineStyles({
                              editorState: state,
                              stylesToApply: checked
                                  ? [availableFeature.name]
                                  : undefined,
                              stylesToRemove: !checked
                                  ? [availableFeature.name]
                                  : undefined,
                          });
                      });
                  }
                : undefined,
            onValueChange: !isSingleToken
                ? (value): void => {
                      setEditorState((state: EditorState): EditorState => {
                          return updateEditorInlineStyles({
                              editorState: state,
                              stylesToApply:
                                  availableFeature
                                      .filter((token) => token.name === value)
                                      .map((token) => token.name) || undefined,
                              stylesToRemove:
                                  availableFeature
                                      .filter((token) => token.name !== value)
                                      .map((token) => token.name) || undefined,
                          });
                      });
                  }
                : undefined,
        };
    });
}

export default function TypeEditorFeatures(): React.ReactElement | null {
    const features = useFeaturesWithState();
    if (!features.length) {
        return null;
    }

    return (
        <>
            {features.map((item): React.ReactElement | null =>
                item.tokens.length > 1 ? (
                    <Select
                        onValueChange={item.onValueChange}
                        value={item.selectedToken?.name}
                        key={item.tokens.join('|')}
                    >
                        {item.tokens.map((token) => (
                            <SelectItem key={token.name} value={token.name}>
                                {token.label}
                            </SelectItem>
                        ))}
                    </Select>
                ) : (
                    <LozengeCheckbox
                        key={item.tokens[0].name}
                        checked={
                            item.indeterminate
                                ? 'indeterminate'
                                : item.selectedToken !== undefined
                        }
                        onCheckedChange={item.onCheckedChange}
                        disabled={item.disabled}
                        value={item.tokens[0].name}
                    >
                        {item.tokens[0].label}
                    </LozengeCheckbox>
                ),
            )}
        </>
    );
}
