/**
 * Given an array like [{tag: 'wght', value: 100}, {tag: 'opsz', value: 5.5}], returns a valid comma-delimited
 * `font-variation-settings` CSS value, in the format:
 * "wght" 100, "opsz" 5.5
 *
 * For cssProps.fontVariationSettings.
 */
export default function valuesToFontVariationSettings(
    values: { tag: string; value: number }[],
): string {
    return values.map((v) => `${JSON.stringify(v.tag)} ${v.value}`).join(',');
}
