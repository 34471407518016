import React from 'react';
import Icon, { IconType } from './Icon';
import * as SelectPrimitive from '@radix-ui/react-select';
import { TEST_ID } from '../settings/E2e';
import styled, { css } from 'styled-components';
import {
    Button as _Button,
    lozengeHorizontalPaddingSpaced,
    lozengeVerticalPaddingSpaced,
    statefulLozengeStyles,
    LOZENGE_VERTICAL_PADDING_DIFF,
    LOZENGE_HORIZONTAL_PADDING_SPACED,
    LOZENGE_HORIZONTAL_PADDING_DIFF,
    LOZENGE_BORDER_RADIUS,
} from './Lozenge';
import { LOZENGE_SPACING, ZINDEX } from '../settings/Global';
import useHasMounted from '../hooks/useHasMounted';
import { useGlobalState } from './GlobalRuntimeState';
import { hideScrollbars } from '../utils/stylesMixins';

export const Placeholder = styled.span`
    color: var(--foregroundColorMix3);
`;

export const Button = styled(_Button)`
    justify-content: space-between;
    gap: ${LOZENGE_SPACING}px;

    /* Select value */
    & span:first-child {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &:hover,
    &[data-state='open'] {
        ${Placeholder} {
            color: var(--lozengeHoverColor, var(--lozengeBackgroundColor));
            background-color: var(
                --lozengeBackgroundHoverColor,
                var(--lozengeColor)
            );
        }
    }
`;

const Item = styled(SelectPrimitive.Item)`
    /*
    Prevent text selection in Firefox when clicking select items.
    See https://github.com/radix-ui/primitives/issues/1488
    */
    user-select: none;

    ${statefulLozengeStyles};

    --lozengeBackgroundColor: var(--menuBackgroundColor);

    display: flex;
    cursor: pointer;
    text-align: left;
    padding: 0 ${LOZENGE_HORIZONTAL_PADDING_SPACED}px;
    margin: ${LOZENGE_VERTICAL_PADDING_DIFF}px 0;
`;

const Content = styled(SelectPrimitive.Content)<{
    $width?: string;
    $minWidth?: string;
    $invertContentColors?: boolean;
}>`
    ${({ $invertContentColors }): ReturnType<typeof css> | null =>
        $invertContentColors
            ? css`
                  color: var(--backgroundColor);

                  --menuBackgroundColor: var(--foregroundColorMix1);
                  --lozengeColor: var(--backgroundColor);
              `
            : null}

    background-color: var(--menuBackgroundColor);
    border-radius: ${LOZENGE_BORDER_RADIUS}px;
    z-index: ${ZINDEX.OVER_NAVBAR};
    max-height: var(--radix-select-content-available-height);
    overflow-y: scroll;
    ${hideScrollbars};
    padding: ${LOZENGE_VERTICAL_PADDING_DIFF}px
        ${LOZENGE_HORIZONTAL_PADDING_DIFF}px;
    min-width: ${({ $width, $minWidth }): string =>
        $width && $minWidth
            ? `max(${$width}, ${$minWidth})`
            : $minWidth
              ? $minWidth
              : $width
                ? $width
                : 'auto'};
`;

const ScrollUpButton = styled(SelectPrimitive.ScrollUpButton)`
    padding: ${lozengeVerticalPaddingSpaced} ${lozengeHorizontalPaddingSpaced};
`;

const ScrollDownButton = styled(SelectPrimitive.ScrollDownButton)`
    padding: ${lozengeVerticalPaddingSpaced} ${lozengeHorizontalPaddingSpaced};
`;

export interface SelectProps extends SelectPrimitive.SelectProps {
    iconType?: IconType;
    hideIcon?: boolean;
    placeholder?: React.ReactNode;
    contentWidth?: string;
    contentMinWidth?: string;
    e2eId?: string;
    sideOffset?: number;
    invertContentColors?: boolean;
}

export const Select = React.forwardRef(
    (
        {
            e2eId,
            children,
            hideIcon,
            placeholder,
            contentWidth,
            contentMinWidth,
            sideOffset,
            invertContentColors,
            iconType = IconType.CHEVRON_DOWN,
            defaultOpen,
            ...props
        }: SelectProps,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ): React.ReactElement => {
        const hasMounted = useHasMounted();
        /*
        We only ever want to have only a single Select open at any time...
        The default Radix select components allows multiple selects to be
        open at the same time, so we implement logic here that stores a ref
        in global state each time a Select opens, and when the next one opens,
        and the ref is set, uses the ref to close the previous one.
         */
        const [openSelectRef, setOpenSelectRef] =
            useGlobalState('openSelectRef');
        const thisOpenRef = React.useRef<boolean>(Boolean(defaultOpen));
        const handleOpenChange = (open: boolean): void => {
            if (open && openSelectRef) {
                // Close any others
                openSelectRef.current = false;
            }
            thisOpenRef.current = open;
            setOpenSelectRef(open ? thisOpenRef : undefined);
        };
        return (
            <SelectPrimitive.Root
                {...props}
                open={thisOpenRef.current}
                onOpenChange={handleOpenChange}
            >
                <SelectPrimitive.Trigger ref={forwardedRef} asChild>
                    <Button data-cy={e2eId}>
                        <SelectPrimitive.Value
                            data-cy={TEST_ID.SELECT_LABEL}
                            placeholder={
                                placeholder !== undefined ? (
                                    <Placeholder>{placeholder}</Placeholder>
                                ) : undefined
                            }
                        />
                        {!hideIcon && (
                            <>
                                {` `}
                                <SelectPrimitive.Icon>
                                    <Icon type={iconType} />
                                </SelectPrimitive.Icon>
                            </>
                        )}
                    </Button>
                </SelectPrimitive.Trigger>
                <SelectPrimitive.Portal>
                    <Content
                        position="popper"
                        sideOffset={sideOffset || LOZENGE_SPACING}
                        $width={contentWidth}
                        $minWidth={contentMinWidth}
                        $invertContentColors={invertContentColors}
                        data-cy={TEST_ID.SELECT_CONTENT}
                    >
                        <ScrollUpButton>
                            <Icon type={IconType.CHEVRON_UP} />
                        </ScrollUpButton>
                        <SelectPrimitive.Viewport>
                            {hasMounted ? children : ' '}
                        </SelectPrimitive.Viewport>
                        <ScrollDownButton>
                            <Icon type={IconType.CHEVRON_DOWN} />
                        </ScrollDownButton>
                    </Content>
                </SelectPrimitive.Portal>
            </SelectPrimitive.Root>
        );
    },
);
Select.displayName = 'Select';

export const SelectItem = React.forwardRef(
    (
        { children, ...props }: SelectPrimitive.SelectItemProps,
        forwardedRef: React.Ref<HTMLDivElement>,
    ) => {
        return (
            <Item {...props} ref={forwardedRef}>
                <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
            </Item>
        );
    },
);
SelectItem.displayName = 'SelectItem';
