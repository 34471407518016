import React from 'react';
import { ContentState, EditorState } from 'draft-js';
import styled from 'styled-components';
import { Select, SelectItem } from './Select';
import { useEditorState } from './TypeEditorContext';
import samples from './TypeEditorTextInput.samples';
import updateEditorInlineStyles from '../utils/type-editor/updateEditorInlineStyles';
import tokens from '../utils/type-editor/tokens';

interface Sample {
    title: string;
    content: string;
}

const Wrap = styled.span`
    --lozengeMinWidth: 150px;
    --lozengeWidth: 10vw;
`;

const TypeEditorTextInput = React.forwardRef(
    (
        props: Record<string, never>,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ): React.ReactElement => {
        const [, setEditorState] = useEditorState();

        const onChange = React.useCallback(
            (value: string): void => {
                const sample = samples[parseInt(value, 10)];
                if (sample) {
                    setEditorState((): EditorState => {
                        return updateEditorInlineStyles({
                            editorState: EditorState.createWithContent(
                                ContentState.createFromText(sample.content),
                            ),
                            // Apply default OpenType features
                            stylesToApply: [tokens.defaults.name],
                        });
                    });
                }
            },
            [setEditorState],
        );
        return (
            <Wrap>
                <Select
                    ref={forwardedRef}
                    onValueChange={onChange}
                    placeholder="Sample text"
                    value={undefined}
                    contentMinWidth="150px"
                    contentWidth="10vw"
                >
                    {samples.map(
                        (sample: Sample, key: number): React.ReactElement => (
                            <SelectItem
                                key={sample.title}
                                value={key.toString()}
                            >
                                {sample.title}
                            </SelectItem>
                        ),
                    )}
                </Select>
            </Wrap>
        );
    },
);
TypeEditorTextInput.displayName = 'TypeEditorTextInput';

export default TypeEditorTextInput;
