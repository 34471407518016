import { FontFeatureSetting } from './FontFeatureSetting';

export type Token = Readonly<{
    name: string;
    names: FontFeatureSetting[];
    optionalName?: FontFeatureSetting;
    label?: string | null;
}>;

export const TOKEN_SEP = '|';
export const TOKEN_TAG_SEP = '*';
export const TOKEN_SECONDARY_TAG_SEP = '&';
export const TOKEN_PREFIX_KLIM = 'KLIM_';

export const enum TokenType {
    OPENTYPE = `${TOKEN_PREFIX_KLIM}OpenType`,
    OPENTYPE_NSTY = `${TOKEN_PREFIX_KLIM}OpenType_Nsty`,
    VARIABLE = `${TOKEN_PREFIX_KLIM}Variable`,
    FAMILY = `${TOKEN_PREFIX_KLIM}FontFamily`,
}

type TokenData = Readonly<{
    type: TokenType;
    names: string[];
    optionalName?: string;
    value?: number;
}>;

/**
 * Returns the token name prefix based on type+names.
 */
export function getTokenNamePrefix(
    type: TokenType,
    names: string[],
    optionalName?: string,
): string {
    const optionalNameString = optionalName
        ? `${TOKEN_SECONDARY_TAG_SEP}${optionalName}`
        : '';
    return `${type}${TOKEN_SEP}${names.join(TOKEN_TAG_SEP)}${optionalNameString}`;
}

/**
 * Token names are structured data. This function
 * compiles a name from data.
 */
export function compileTokenName({
    type,
    names,
    optionalName,
    value,
}: TokenData): string {
    const prefix = getTokenNamePrefix(type, names, optionalName);
    if (value !== undefined) {
        return `${prefix}${TOKEN_SEP}${value.toString(10)}`;
    }
    return prefix;
}

/**
 * Token names are structured data. This function
 * decompiles a name into data.
 */
export function decompileTokenName(tokenName: string): TokenData | undefined {
    if (!tokenName.startsWith(TOKEN_PREFIX_KLIM)) {
        return;
    }
    const parts = tokenName.split(TOKEN_SEP);
    if (parts.length < 2 || parts.length > 3) {
        throw new Error(`Invalid token: ${tokenName}`);
    }
    const optionalNameArray = parts[1].split(TOKEN_SECONDARY_TAG_SEP);
    const optionalName =
        optionalNameArray.length > 1 ? optionalNameArray.pop() : undefined;
    const names = optionalNameArray[0].split(TOKEN_TAG_SEP);
    return {
        type: parts[0] as TokenType,
        names,
        optionalName,
        value: parts.length === 3 ? parseFloat(parts[2]) : undefined,
    };
}

function _tokenDef({
    names,
    optionalName,
    label,
    isNsty,
}: {
    names: FontFeatureSetting[];
    optionalName?: FontFeatureSetting;
    label?: string;
    isNsty?: boolean;
}): Token {
    return {
        name: compileTokenName({
            type: isNsty ? TokenType.OPENTYPE_NSTY : TokenType.OPENTYPE,
            names,
            optionalName,
        }),
        names,
        optionalName,
        label,
    };
}

// All names should be prefixed with 'KLIM' as a clash-prevention measure.
const tokens: Record<string, Token> = {
    /*
     * These `defaults` OpenType features are hard-coded for all Type Editors.
     * If they are altered they should also be altered in back end code
     * as this will affect word width calculations and other things.
     *
     * See: backend/fonts/opentype.py
     *
     * For background on why we hard-code some of these see:
     * https://twitter.com/klimtypefoundry/status/1142156839825498112
     */
    defaults: _tokenDef({
        names: [
            FontFeatureSetting.kern,
            FontFeatureSetting.liga,
            FontFeatureSetting.clig,
            FontFeatureSetting.calt,
        ],
    }),
    /*
    All non-default, non-hardcoded ones, below
     */
    smcp: _tokenDef({
        names: [FontFeatureSetting.smcp],
    }),
    c2sc: _tokenDef({
        names: [FontFeatureSetting.c2sc],
    }),
    dlig: _tokenDef({
        names: [FontFeatureSetting.dlig],
    }),
    swsh: _tokenDef({
        names: [FontFeatureSetting.swsh],
    }),
    numstyDefault: _tokenDef({
        names: [],
        label: 'Default numerals',
        isNsty: true,
    }),
    numstyLining: _tokenDef({
        names: [FontFeatureSetting.lnum],
        optionalName: FontFeatureSetting.pnum,
        label: 'Lining numerals',
        isNsty: true,
    }),
    numstyOldstyle: _tokenDef({
        names: [FontFeatureSetting.onum],
        optionalName: FontFeatureSetting.pnum,
        label: 'Old-style numerals',
        isNsty: true,
    }),
    numstyTabularLining: _tokenDef({
        names: [FontFeatureSetting.tnum],
        optionalName: FontFeatureSetting.lnum,
        label: 'Tabular lining numerals',
        isNsty: true,
    }),
    numstyTabularOldstyle: _tokenDef({
        names: [FontFeatureSetting.tnum, FontFeatureSetting.onum],
        label: 'Tabular old-style numerals',
        isNsty: true,
    }),
    zero: _tokenDef({
        names: [FontFeatureSetting.zero],
    }),
    frac: _tokenDef({
        names: [FontFeatureSetting.frac],
    }),
    sups: _tokenDef({
        names: [FontFeatureSetting.sups],
    }),
    sinf: _tokenDef({
        names: [FontFeatureSetting.sinf],
    }),
    ordn: _tokenDef({
        names: [FontFeatureSetting.ordn],
    }),
    case: _tokenDef({
        names: [FontFeatureSetting.case],
    }),
};

// Add Stylistic Set tokens
Array.from(Array(20)).map((_, idx) => {
    const token: FontFeatureSetting = (
        idx < 9 ? `ss0${idx + 1}` : `ss${idx + 1}`
    ) as FontFeatureSetting;
    tokens[token] = _tokenDef({ names: [token] });
});

export default tokens;
