import React from 'react';
import styled from 'styled-components';
import FontFamilyOpenTypeFeaturesList from './FontFamilyOpenTypeFeaturesList';
import FontFamilyOpenTypeFeaturesDetails from './FontFamilyOpenTypeFeaturesDetails';
import { FontFamilyOpenTypeFeatureProvider } from './FontFamilyOpenTypeFeaturesContext';
import useFontFamilyOpenTypeFeaturesSorted from '../hooks/useFontFamilyOpenTypeFeaturesSorted';
import { VIEWPORT, FontSectionIds } from '../settings/Global';
import { useGlobalState } from './GlobalRuntimeState';
import useLocationHash from '../hooks/useLocationHash';
import ClientOnly from './ClientOnly';

const Wrap = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-gap: var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-gap: var(--spacing4);
    }
`;

function FontFamilyOpenTypeFeatures(): React.ReactElement | null {
    return (
        <Wrap>
            {/* Render client-only as otherwise initial tab from URL # doesn't activate properly */}
            <ClientOnly>
                <FontFamilyOpenTypeFeaturesList />
                <FontFamilyOpenTypeFeaturesDetails />
            </ClientOnly>
        </Wrap>
    );
}

export default function FontFamilyOpenTypeFeaturesWrap(): React.ReactElement | null {
    const [, setSectionInViewData] = useGlobalState('sectionInViewData');
    const locationHash = useLocationHash();

    const initialTag = React.useMemo(() => {
        if (
            !locationHash ||
            !locationHash.startsWith(`#${FontSectionIds.OPEN_TYPE}`)
        ) {
            return;
        }
        const hashParts = locationHash.split('/');
        if (hashParts.length < 2) {
            return;
        }
        return hashParts[1];
    }, []);

    const fontFamilyOpenTypeFeatures = useFontFamilyOpenTypeFeaturesSorted();
    const openTypeFeature = React.useMemo(() => {
        if (fontFamilyOpenTypeFeatures.length === 0) {
            return;
        }
        if (initialTag) {
            const featureFromTag = fontFamilyOpenTypeFeatures.find(
                (otf) => otf.tag === initialTag,
            );
            if (featureFromTag) {
                return featureFromTag;
            }
        }
        return (
            fontFamilyOpenTypeFeatures.find((feat) => feat.loadAsDefault) ||
            fontFamilyOpenTypeFeatures[0]
        );
    }, [fontFamilyOpenTypeFeatures]);

    if (!openTypeFeature) {
        setSectionInViewData(undefined);
        return null;
    }
    return (
        <FontFamilyOpenTypeFeatureProvider
            initial={{
                openTypeFeature,
                isOn: true,
            }}
        >
            <FontFamilyOpenTypeFeatures />
        </FontFamilyOpenTypeFeatureProvider>
    );
}
