/**
 * Given an array like ['smcp', 'swsh'], returns a valid comma-delimited
 * `font-feature-settings` CSS value, in the format:
 * "smcp", "swsh"
 *
 * For cssProps.fontFeatureSettings.
 */
export default function openTypeTagsToFontFeatureSettings(
    tags: string[],
    turnOff?: boolean,
    quoteSymbol?: string,
): string {
    return tags
        .map((f) => {
            let quotedTag = JSON.stringify(f);
            if (quoteSymbol) {
                quotedTag = quotedTag.replaceAll('"', quoteSymbol);
            }
            return `${quotedTag}${turnOff ? ' 0' : ''}`;
        })
        .join(',');
}
