import React from 'react';
import styled from 'styled-components';
import { Tabs as TabsPrimitive } from 'radix-ui';
import { lozengeStyles } from './Lozenge';
import { ButtonProps, buttonStyles } from './Button';

const Root = styled(TabsPrimitive.Root)``;
const TabsList = styled(TabsPrimitive.List)`
    --lozengeBackgroundColor: var(--menuBackgroundColor);
    --lozengeBackgroundHoverColor: var(--backgroundColor);
    ${lozengeStyles};

    padding: 0;
`;
const TabTrigger = styled(TabsPrimitive.Trigger).attrs<ButtonProps>(
    (props) => ({
        // Change defaults
        $slim: props['$slim'] !== undefined ? props['$slim'] : true,
        $type: props['$type'] !== undefined ? props['$type'] : 'tertiary',
    }),
)`
    ${buttonStyles};
`;
const TabContent = styled(TabsPrimitive.Content).attrs<
    React.ComponentProps<typeof TabsPrimitive.Content>
>((props) => ({
    // Change defaults
    forceMount: props['forceMount'] !== undefined ? props['forceMount'] : true,
}))`
    &[data-state='inactive'] {
        display: none;
    }
`;

export { TabsList, TabTrigger, TabContent };

const Tabs = React.forwardRef(
    (
        props: TabsPrimitive.TabsProps,
        forwardedRef: React.Ref<HTMLDivElement>,
    ): React.ReactElement => {
        return (
            <Root ref={forwardedRef} {...props}>
                {props.children}
            </Root>
        );
    },
);
Tabs.displayName = 'Tabs';

export default Tabs;
