import React from 'react';
import { useInView, InViewHookResponse } from 'react-intersection-observer';
import { useGlobalState } from '../components/GlobalRuntimeState';

// Add a negative top and bottom margin to the viewport so that sections do not
// trigger until they are further into the visible screen area
const ROOT_MARGIN = '-100px 0px -67% 0px';
const THRESHOLD = [0];

type InViewRef = InViewHookResponse[0];
type CreateIntersectionObserverRef = (
    sectionId: string,
    rootMargin?: string,
    threshold?: number | number[],
) => InViewRef;
interface SectionStackEntry {
    id: string;
    inView: boolean;
}

export default function (): [CreateIntersectionObserverRef] {
    const [, setSectionInView] = useGlobalState('sectionInView');
    const [sectionStack, setSectionStack] = React.useState<SectionStackEntry[]>(
        [],
    );

    const addToStack = React.useCallback(
        (id: string): void => {
            sectionStack.push({
                id,
                inView: true,
            });
            setSectionStack(sectionStack);
            setSectionInView(id);
        },
        [sectionStack, setSectionInView],
    );

    const updateSectionInView = React.useCallback((): void => {
        let newSectionInView;
        // Go through the stack and select bottom-most section that is inView
        // Note that "inView" is just the top 1/3rd of the viewport (as per ROOT_MARGIN)
        for (let i = sectionStack.length - 1; i >= 0; i -= 1) {
            if (!newSectionInView && sectionStack[i].inView) {
                newSectionInView = sectionStack[i].id;
                break;
            }
        }
        setSectionInView(newSectionInView);
    }, [sectionStack, setSectionInView]);

    const createIntersectionObserverRef = React.useCallback(
        (
            sectionId: string,
            rootMargin: string = ROOT_MARGIN,
            threshold: number | number[] = THRESHOLD,
        ): InViewRef => {
            const [sectionRef, inView] = useInView({
                threshold,
                rootMargin,
            });

            React.useEffect((): void => {
                // Ignore inView triggers that happen during page load
                if (window.scrollY === 0) return;

                const matchingStackEntry = sectionStack.find(
                    (o): boolean => o.id === sectionId,
                );

                if (!matchingStackEntry) {
                    // Ignore initial render of sections if they are not above the fold
                    if (!inView) return;
                    // Add sections to the stack as they come into view
                    addToStack(sectionId);
                } else {
                    matchingStackEntry.inView = inView;
                    setSectionStack(sectionStack);
                }
                updateSectionInView();
            }, [inView, sectionId]);
            return sectionRef;
        },
        [sectionStack, addToStack, updateSectionInView],
    );

    React.useEffect((): (() => void) => {
        return (): void => {
            setSectionInView(undefined);
        };
    }, [setSectionInView]);

    return [createIntersectionObserverRef];
}
