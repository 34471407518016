import createStatefulContext from '../utils/createStatefulContext';
import { type FontFamiliesQuery } from '../gql/api-ssr';

export const {
    Provider: FontFamilyOpenTypeFeatureProvider,
    useState: useOpenTypeFeatureState,
} = createStatefulContext<{
    openTypeFeature: FontFamiliesQuery['ssr']['fontFamilies'][number]['openTypeFeatures'][number];
    isOn: boolean;
}>();
