import { Link as GatsbyLink } from 'gatsby';
import styled, { css } from 'styled-components';
import { statefulLozengeStyles } from './Lozenge';
import { BUTTON_AND_FIELD_HEIGHT, LOZENGE_HEIGHT } from '../settings/Global';

export type ButtonProps = {
    $type?: 'secondary' | 'tertiary' | 'withBackground';
    $slim?: boolean;
};

export const buttonStyles = css<ButtonProps>`
    --lozengeColor: var(--backgroundColor);
    --lozengeHoverColor: var(--backgroundColor);
    --lozengeBackgroundColor: var(--foregroundColor);
    --lozengeBackgroundHoverColor: var(--foregroundColorMix3);
    --lozengeBorderStyle: solid;
    --lozengeBorderColor: var(--foregroundColor);
    --lozengeHoverBorderColor: var(--foregroundColorMix3);
    --lozengeBackgroundActiveColor: var(--lozengeBackgroundHoverColor);
    ${({ $type }): ReturnType<typeof css> | null => {
        switch ($type) {
            case 'secondary':
                return css`
                    --lozengeColor: var(--foregroundColor);
                    --lozengeBackgroundColor: var(--backgroundColor);
                    --lozengeBackgroundHoverColor: var(--foregroundColor);
                    --lozengeHoverBorderColor: var(--foregroundColor);
                `;
            case 'tertiary':
                return css`
                    --lozengeColor: var(--foregroundColor);
                    --lozengeBackgroundColor: var(--menuBackgroundColor);
                    --lozengeBackgroundHoverColor: var(--foregroundColor);
                    --lozengeBorderColor: var(--menuBackgroundColor);
                    --lozengeHoverBorderColor: var(--foregroundColor);

                    &[data-state='active'] {
                        --lozengeBorderColor: var(
                            --lozengeBackgroundActiveColor
                        );
                    }
                `;
            case 'withBackground':
                return css`
                    --lozengeColor: var(--foregroundColor);
                    --lozengeBackgroundColor: transparent;
                    --lozengeBackgroundHoverColor: var(--foregroundColor);
                    --lozengeBorderColor: var(--foregroundColorMix6);
                    --lozengeHoverBorderColor: var(--foregroundColor);
                `;
        }
        return null;
    }};

    ${statefulLozengeStyles};
    height: ${({ $slim }): number =>
        $slim ? LOZENGE_HEIGHT : BUTTON_AND_FIELD_HEIGHT}px;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    text-wrap: nowrap;
`;

const Button = styled.button<ButtonProps>`
    ${buttonStyles};
`;

export default Button;

export const InlineButton = styled(Button)<ButtonProps>`
    width: 100%;

    /*
    TODO: Check
    line-height: var(--lineHeightBody2);
    padding: var(--spacing1) var(--spacing2);
     */
`;

export const Link = styled(GatsbyLink)<ButtonProps>`
    ${buttonStyles};
`;

export const ExternalLink = styled.a<ButtonProps>`
    ${buttonStyles};
`;
