import React from 'react';
import { css } from 'styled-components';

import type { UnionFontVariableAxisValue } from '../components/TypeEditorContext';
import valuesToFontVariationSettings from './valuesToFontVariationSettings';

type CssRenderInfo = {
    fontFamilyName: string;
    isItalic: boolean;
    variableAxisValues: UnionFontVariableAxisValue[] | null;
};

export function getCssPropsFromRenderInfo(
    info: CssRenderInfo,
): React.CSSProperties {
    const cssProps: React.CSSProperties = {
        fontFamily: `'${info.fontFamilyName}'`,
        fontStyle: info.isItalic ? 'italic' : 'normal',
    };

    if (info.variableAxisValues) {
        cssProps.fontVariationSettings = valuesToFontVariationSettings(
            info.variableAxisValues,
        );
    }

    return cssProps;
}

export default function getCssFromRenderInfo(
    info: CssRenderInfo,
): ReturnType<typeof css> {
    return css`
        font-family: '${info.fontFamilyName}';
        font-style: ${info.isItalic ? 'italic' : 'normal'};
        ${info.variableAxisValues
            ? css`
                  font-variation-settings: ${info.variableAxisValues
                      .map((v) => `"${v.tag}" ${v.value}`)
                      .join(',')};
              `
            : null};
    `;
}
