import { type OpenTypeFeatureMetaData } from '../hooks/useConfig';
import {
    type CMS_SSR_FontOpenTypeFeatureInterface,
    type FontFamiliesQuery,
} from '../gql/api-ssr';

export default function getOpenTypeFeatureLabel(
    tag: string,
    openTypeFeatureMetaData: OpenTypeFeatureMetaData[],
    fontFamilyOpenTypeFeatures?:
        | CMS_SSR_FontOpenTypeFeatureInterface[]
        | FontFamiliesQuery['ssr']['fontFamilies'][number]['openTypeFeatures'],
    openTypeFeature?: FontFamiliesQuery['ssr']['fontFamilies'][number]['openTypeFeatures'][number],
): string | undefined | null {
    let output: string | null | undefined;

    if (openTypeFeature) {
        output = openTypeFeature.label;
    } else if (fontFamilyOpenTypeFeatures) {
        // First look for a custom label, if we're passing in FontFamily features
        output = fontFamilyOpenTypeFeatures.find(
            (feat) => feat.tag === tag,
        )?.label;
    }

    // Look up in Config
    if (output === undefined || output === null) {
        output = openTypeFeatureMetaData.find(
            (feat) => feat.tag === tag,
        )?.defaultLabel;
    }

    return output;
}
