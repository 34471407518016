import notUndefined from '../utils/notUndefined';

export interface GlyphCatDef {
    title: string;
    hidden?: boolean;
    orderedGlyphNames: string[];
}

/**
 * Helper to generate alternate names.
 */
function alts(
    name: string,
    options?: {
        add_dot_i?: boolean;
        add_dot_round?: boolean;
        add_dot_school?: boolean;
    },
): string[] {
    return [
        `${name}.1`,
        `${name}.alt`,
        options?.add_dot_i ? `${name}.i` : undefined,
        options?.add_dot_round ? `${name}.round` : undefined,
        options?.add_dot_school ? `${name}.school` : undefined,
    ].filter(notUndefined);
}

/**
 * Helper to generate alternate combining names.
 */
function cmbAlts(name: string): string[] {
    return [name, `${name}.cap`, `${name}cmb`];
}

/**
 * Helper to generate Vietnamese glyphs names.
 */
function vietAlts(name: string, suffix: string = ''): string[] {
    return [
        `${name}${suffix}`,
        `${name}acute${suffix}`,
        `${name}grave${suffix}`,
        `${name}dotbelow${suffix}`,
        `${name}hoi${suffix}`,
        `${name}tilde${suffix}`,
    ];
}

/**
 * Helper to generate numeral glyph names.
 */
function numSet(suffix: string = ''): string[] {
    return [
        `zero${suffix}`,
        `one${suffix}`,
        `two${suffix}`,
        `three${suffix}`,
        `four${suffix}`,
        `five${suffix}`,
        `six${suffix}`,
        `seven${suffix}`,
        `eight${suffix}`,
        `nine${suffix}`,
    ];
}

export const GLYPH_CAT_DEFS: GlyphCatDef[] = [
    {
        title: 'Hidden',
        hidden: true,
        orderedGlyphNames: [
            // Combining accents & components
            ...cmbAlts('acute'),
            'uni0301', // acute
            'uni0315', // acute (another one)
            'uni030B', // double acute
            ...cmbAlts('breve'),
            ...cmbAlts('cedilla'),
            'uni0327', // cedilla
            'altcedilla',
            'cedilla.alt',
            'space_dotbelowcomb',
            'space_uni0326',
            'space_uni0326.alt',
            ...cmbAlts('caron'),
            'caron.Slovak',
            'caronSlovak',
            'uni030C', // caron
            ...cmbAlts('circumflex'),
            'uni0302', // circumflex
            'commaabovecmb',
            'commaaboverightcm',
            'commaaboverightcmb',
            ...cmbAlts('commaaccent'),
            'uni00200326', // commaaccent
            'uni0326', // commaaccent
            'commaaccent.round',
            ...cmbAlts('commaturn'),
            'commaturn.round',
            'uni02BB', // commaturnedabove
            'uni0312', // commaturnedabove
            'dieresis.round',
            'dieresis.cap.round',
            'dotbelow.round',
            'uni0323', // dot
            ...cmbAlts('dotaccent'),
            'dotaccent.round',
            'dotaccentCap',
            'dotaccent.cap.i',
            'dotaccent.i',
            'dotaccent.cap.round',
            'uni0307', // dotaccent
            ...cmbAlts('dotbelow'),
            'uni00200323', //dotbelow
            'dotlessj',
            'uni0237', // dotlessj
            'uni0237.sc', // dotlessj
            ...cmbAlts('doublegrave'),
            ...cmbAlts('grave'),
            'uni0300', // grave
            'uni0329', // linebelow
            'uni0320', // linebelow
            ...cmbAlts('macron'),
            'uni02C9', // macron
            'uni0304', // macron
            'uni0331', // COMBINING MACRON BELOW
            ...cmbAlts('dieresis'),
            'dieresis.i',
            'dieresis.cap.i',
            'dieresisCap',
            'uni0308', // umlaut
            ...cmbAlts('invertedbreve'),
            'uni0306', // invertedbreve
            ...cmbAlts('hungarumlaut'),
            ...cmbAlts('ogonek'),
            'uni0328', // ogonek
            ...cmbAlts('ring'),
            'uni030A', // ring
            'rinvertedbreve',
            ...cmbAlts('tilde'),
            'uni0303', // tilde
            // Vietnamese
            ...cmbAlts('hookabove'),
            ...cmbAlts('horn'),
            ...cmbAlts('breveacute'),
            ...cmbAlts('brevegrave'),
            ...cmbAlts('brevehookabove'),
            ...cmbAlts('brevetilde'),
            ...cmbAlts('circumflexacute'),
            ...cmbAlts('circumflexgrave'),
            ...cmbAlts('circumflexhookabove'),
            ...cmbAlts('circumflextilde'),
            // Others to be hidden
            '.notdef',
            'fraction', // fraction bar for combining into dynamic fractions
            'bullseye', // Klim logo in Feijoa
            'lowline',
            'i.dot',
            'anoteleia',
            // These next four are only present in National 2 Narrow Extrabold.
            // They are a mistake in the font. They're supposed to be for interpolation
            // only, but must have drifted into the production fonts.
            'dollar.1',
            'cent.1',
            'dollar.1.lt',
            'cent.1.lt',
        ],
    },
    {
        title: 'Uppercase',
        orderedGlyphNames: [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
        ],
    },
    {
        title: 'Uppercase alternates',
        orderedGlyphNames: [
            ...alts('A'),
            'B.1',
            'C.1',
            'D.1',
            'E.1',
            'F.1',
            ...alts('G'),
            'H.1',
            'I.1',
            'J.1',
            'K.1',
            'L.1',
            ...alts('M'),
            ...alts('N'),
            'O.1',
            'P.1',
            'Q.1',
            ...alts('R'),
            ...alts('S'),
            'T.1',
            'U.1',
            ...alts('V'),
            ...alts('W'),
            'W.editalt.alt',
            'W.brandalt',
            'X.1',
            'Y.1',
            'Z.1',
        ],
    },
    {
        title: 'Lowercase',
        orderedGlyphNames: [
            'a',
            'b',
            'c',
            'd',
            'e',
            'f',
            'g',
            'h',
            'i',
            'j',
            'k',
            'l',
            'm',
            'n',
            'o',
            'p',
            'q',
            'r',
            's',
            't',
            'u',
            'v',
            'w',
            'x',
            'y',
            'z',
        ],
    },
    {
        title: 'Lowercase alternates',
        orderedGlyphNames: [
            ...alts('a', { add_dot_school: true }),
            'c.1',
            ...alts('e'),
            'f.1',
            'f.short',
            'f_short',
            ...alts('g', { add_dot_school: true }),
            'g.2',
            'g.3',
            'h.1',
            'i.i',
            'i.round',
            'j.brandalt',
            'j.i',
            'j.round',
            'k.1',
            'm.1',
            'n.1',
            'r.1',
            'r.2',
            ...alts('s'),
            ...alts('t'),
            ...alts('u'),
            ...alts('v'),
            ...alts('w'),
            ...alts('y', { add_dot_school: true }),
            'z.1',
        ],
    },
    {
        title: 'Small capitals',
        orderedGlyphNames: [
            'A.sc',
            'B.sc',
            'C.sc',
            'D.sc',
            'E.sc',
            'F.sc',
            'G.sc',
            'H.sc',
            'I.sc',
            'J.sc',
            'K.sc',
            'L.sc',
            'M.sc',
            'N.sc',
            'O.sc',
            'P.sc',
            'Q.sc',
            'R.sc',
            'S.sc',
            'T.sc',
            'U.sc',
            'V.sc',
            'W.sc',
            'X.sc',
            'Y.sc',
            'Z.sc',
            'a.smcp',
            'b.smcp',
            'c.smcp',
            'd.smcp',
            'e.smcp',
            'f.smcp',
            'g.smcp',
            'h.smcp',
            'i.smcp',
            'j.smcp',
            'k.smcp',
            'l.smcp',
            'm.smcp',
            'n.smcp',
            'o.smcp',
            'p.smcp',
            'q.smcp',
            'r.smcp',
            's.smcp',
            't.smcp',
            'u.smcp',
            'v.smcp',
            'w.smcp',
            'x.smcp',
            'y.smcp',
            'z.smcp',
        ],
    },
    {
        title: 'Small capital alternates',
        orderedGlyphNames: [
            'B.1sc',
            'B.1.sc',
            'D.1sc',
            'D.1.sc',
            'P.1sc',
            'P.1.sc',
            'R.1sc',
            'R.1.sc',
            'ralt.smcp',
        ],
    },
    {
        title: 'Uppercase accents',
        orderedGlyphNames: [
            'Aacute',
            ...vietAlts('Abreve'),
            'Acaron',
            ...vietAlts('Acircumflex'),
            'Adieresis',
            'Adieresismacron',
            'uni004100A800AF', // Adieresismacron
            'Adotbelow',
            'Adotabove',
            'Adoublegrave',
            'Agrave',
            'Ahoi',
            'Ainvertedbreve',
            'Amacron',
            'Anocross',
            'Aogonek',
            'Aring',
            'Aringacute',
            'uni004102DA00B4', // Aringacute
            'Atilde',
            'AE',
            'AEacute',
            'AEmacron',
            'Cacute',
            'Ccaron',
            'Ccedilla',
            'Ccircumflex',
            'Cdotaccent',
            'Dcaron',
            'Dcedilla',
            'Ddotbelow',
            'Dcroat',
            'Eacute',
            'Edotbelowacute',
            'uni004500B40323', // Eacutedotbelow
            'Ebreve',
            'Ecaron',
            ...vietAlts('Ecircumflex'),
            'Edieresis',
            'Edotaccent',
            'Edotbelow',
            'uni1EB8', // Edotbelow
            'Edoublegrave',
            'Egrave',
            'Ehoi',
            'uni004500600323', // Egravedotbelow
            'Einvertedbreve',
            'Emacron',
            'Eogonek',
            'Etilde',
            'Eth',
            'Gacute',
            'Gbreve',
            'Gcaron',
            'Gcircumflex',
            'Gcommaaccent',
            'Gcommaturnedabove',
            'uni0122', // Gcommabelow
            'Gdotaccent',
            'Gstroke',
            'Gtilde',
            'Hbar',
            'Hcaron',
            'Hcircumflex',
            'Hdotbelow',
            'Hmacronbelow',
            'IJ',
            'IJacute',
            'Iacute',
            'Ibreve',
            'Icaron',
            'Icircumflex',
            'Idieresis',
            'Idotaccent',
            'Idotbelow',
            'Idoublegrave',
            'Igrave',
            'Ihoi',
            'Iinvertedbreve',
            'Imacron',
            'Iogonek',
            'Itilde',
            'Jacute',
            'Jcaron',
            'Jcircumflex',
            'Kcaron',
            'Kcedilla',
            'Kcommaaccent',
            'uni0136', // Kcommabelow
            'Khook',
            'Lacute',
            'Lcaron',
            'Lcedilla',
            'Lcommaaccent',
            'uni013B', // Lcommabelow
            'Ldot',
            'Ldotbelow',
            'uni1E36', // Ldotbelow
            'uni1E38', // Lmacrondotbelow
            'uni004C032300AF', // Lmacrondotbelow
            'Lslash',
            'Mcedilla',
            'Mdotbelow',
            'Mmacron',
            'Nacute',
            'Ncaron',
            'Ncedilla',
            'Ncommaaccent',
            'uni0145', // Ncommabelow
            'Ndescender',
            'Ndieresis',
            'Ndotaccent',
            'Ndotbelow',
            'Ngrave',
            'Nmacron',
            'Ntilde',
            'Eng',
            'Oacute',
            'Obreve',
            'Ocaron',
            'Ocedilla',
            'uni01EC', // Ocedillamacron
            'uni004F02DB00AF', // Ocedillamacron
            ...vietAlts('Ocircumflex'),
            'Ocommaturnedabove',
            'Odieresis',
            'Odotabove',
            'uni004F02D900AF', // Odotabovemacron
            'uni004F0329', // Olinebelow
            'Odotbelow',
            'Odoublegrave',
            'Ograve',
            'Ohoi',
            ...vietAlts('Ohorn'),
            'Ohungarumlaut',
            'Oinvertedbreve',
            'Omacron',
            'uni0230', // Omacrondotabove
            'Oogonek',
            'Oslash',
            'Oslashacute',
            'Otilde',
            'Otildemacron',
            'Ounderlineaccent',
            'barredO',
            'OE',
            'Pmacron',
            'Racute',
            'Rcaron',
            'Rcedilla',
            'Rcommaaccent',
            'uni0156', // Rcommabelow
            'Rdotbelow',
            'Rdotbelowmacron',
            'Rdoublegrave',
            'Rinvertedbreve',
            'Sacute',
            'Scaron',
            'Scedilla',
            'uni015E', // Scedilla
            'Scircumflex',
            'Scommaaccent',
            'uni0218', // Scommabelow
            'Sdotbelow',
            'Germandbls',
            'Tbar',
            'Tcaron',
            'Tcedilla',
            'uni0162', // Tcedilla
            'Tcommaaccent',
            'Tdotbelow',
            'uni021A', // Tcommabelow
            'Uacute',
            'Ubreve',
            'Ucaron',
            'Ucircumflex',
            'Udieresis',
            'uni01D9', // Udieresiscaron
            'Udotbelow',
            'Udoublegrave',
            'Ugrave',
            'Uhoi',
            ...vietAlts('Uhorn'),
            'Uhungarumlaut',
            'Uinvertedbreve',
            'Umacron',
            'Umacronbelow',
            'Uogonek',
            'Uring',
            'Utilde',
            'Wacute',
            'Wcircumflex',
            'Wdieresis',
            'Wgrave',
            'Yacute',
            'Ycircumflex',
            'Ydieresis',
            'Ydotbelow',
            'Ygrave',
            'Yhoi',
            'Ymacron',
            'Ytilde',
            'Zacute',
            'Zcaron',
            'Zdotaccent',
            'Thorn',
            'Schwa',
            'Ezh',
            'Ezhcaron',
        ],
    },
    {
        title: 'Uppercase accent alternates',
        orderedGlyphNames: [
            'space_uni0326.alt',
            ...alts('Aacute'),
            ...alts('Abreve'),
            'Abrevedotbelow.round',
            ...alts('Acaron'),
            ...alts('Acircumflex'),
            'Acircumflexdotbelow.round',
            ...alts('Adieresis', { add_dot_i: true, add_dot_round: true }),
            'Aumlaut.alt',
            ...alts('Adieresismacron'),
            ...alts('uni004100A800AF'),
            ...alts('Adotabove'),
            'Adotbelow.round',
            ...alts('Adoublegrave'),
            ...alts('Agrave'),
            ...alts('Ainvertedbreve'),
            ...alts('Amacron'),
            ...alts('Anocross'),
            ...alts('Aogonek'),
            ...alts('Aring'),
            ...alts('Aringacute'),
            ...alts('uni004102DA00B4'),
            ...alts('Atilde'),
            ...alts('AE'),
            ...alts('AEacute'),
            ...alts('AEmacron'),
            ...alts('Cacute'),
            ...alts('Ccaron'),
            ...alts('Ccedilla'),
            ...alts('Ccircumflex'),
            ...alts('Cdotaccent', { add_dot_i: true, add_dot_round: true }),
            ...alts('Dcaron'),
            ...alts('Dcedilla'),
            ...alts('Ddotbelow'),
            ...alts('Dcroat'),
            ...alts('Eacute'),
            ...alts('uni004500B40323'),
            ...alts('Ebreve'),
            ...alts('Ecaron'),
            ...alts('Ecircumflex'),
            'Ecircumflexdotbelow.round',
            ...alts('Edieresis', { add_dot_i: true, add_dot_round: true }),
            ...alts('Edotaccent', { add_dot_i: true, add_dot_round: true }),
            ...alts('Edoublegrave'),
            ...alts('Egrave'),
            ...alts('uni004500600323'),
            ...alts('Einvertedbreve'),
            'Edotbelow.round',
            ...alts('Emacron'),
            ...alts('Eogonek'),
            ...alts('Eth'),
            ...alts('Gacute'),
            ...alts('Gbreve'),
            ...alts('Gcaron'),
            ...alts('Gcircumflex'),
            ...alts('Gcommaaccent', { add_dot_round: true }),
            ...alts('Gcommaturnedabove'),
            ...alts('uni0122'),
            ...alts('Gdotaccent', { add_dot_i: true, add_dot_round: true }),
            ...alts('Gstroke'),
            ...alts('Gtilde'),
            ...alts('Hbar'),
            ...alts('Hcaron'),
            ...alts('Hcircumflex'),
            ...alts('Hdotbelow', { add_dot_round: true }),
            ...alts('Hmacronbelow'),
            ...alts('IJ'),
            ...alts('Iacute'),
            ...alts('Ibreve'),
            ...alts('Icaron'),
            ...alts('Icircumflex'),
            ...alts('Idieresis', { add_dot_i: true, add_dot_round: true }),
            ...alts('Idotaccent', { add_dot_i: true, add_dot_round: true }),
            ...alts('Idotbelow', { add_dot_round: true }),
            ...alts('Idoublegrave'),
            ...alts('Igrave'),
            ...alts('Iinvertedbreve'),
            ...alts('Imacron'),
            ...alts('Iogonek'),
            ...alts('Itilde'),
            ...alts('Jcaron'),
            ...alts('Jcircumflex'),
            ...alts('Kcaron'),
            ...alts('Kcedilla'),
            ...alts('Kcommaaccent', { add_dot_round: true }),
            ...alts('uni0136'),
            ...alts('Khook'),
            ...alts('Lacute'),
            ...alts('Lcaron'),
            ...alts('Lcedilla'),
            ...alts('Lcommaaccent', { add_dot_round: true }),
            ...alts('uni013B'),
            ...alts('Ldot', { add_dot_i: true, add_dot_round: true }),
            ...alts('Ldotbelow'),
            ...alts('uni1E36'),
            ...alts('uni1E38'),
            ...alts('uni004C032300AF'),
            ...alts('Lslash'),
            ...alts('Mcedilla'),
            ...alts('Mdotbelow'),
            ...alts('Mmacron'),
            ...alts('Nacute'),
            ...alts('Ncaron'),
            ...alts('Ncedilla'),
            ...alts('Ncommaaccent', { add_dot_round: true }),
            ...alts('uni0145'),
            ...alts('Ndescender'),
            ...alts('Ndieresis'),
            ...alts('Ndotaccent'),
            ...alts('Ndotbelow'),
            ...alts('Ngrave'),
            ...alts('Nmacron'),
            ...alts('Ntilde'),
            ...alts('Eng'),
            ...alts('Oacute'),
            ...alts('Obreve'),
            ...alts('Ocaron'),
            ...alts('Ocedilla'),
            ...alts('uni01EC'),
            ...alts('uni004F02DB00AF'),
            ...alts('Ocircumflex'),
            'Ocircumflexdotbelow.round',
            ...alts('Ocommaturnedabove'),
            ...alts('Odieresis', { add_dot_i: true, add_dot_round: true }),
            'Oumlaut.alt',
            ...alts('Odotabove'),
            ...alts('uni004F02D900AF'),
            ...alts('uni004F0329'),
            ...alts('Odotbelow', { add_dot_round: true }),
            ...alts('Odoublegrave'),
            ...alts('Ograve'),
            'Ohorndotbelow.round',
            ...alts('Ohungarumlaut'),
            ...alts('Oinvertedbreve'),
            ...alts('Omacron'),
            ...alts('uni0230'),
            ...alts('Oogonek'),
            ...alts('Oslash'),
            ...alts('Oslashacute'),
            ...alts('Otilde'),
            ...alts('Otildemacron'),
            ...alts('Ounderlineaccent'),
            ...alts('barredO'),
            ...alts('OE'),
            ...alts('Pmacron'),
            ...alts('Racute'),
            ...alts('Rcaron'),
            ...alts('Rcedilla'),
            ...alts('Rcommaaccent', { add_dot_round: true }),
            'Rcommaaccent.1.round',
            ...alts('uni0156'),
            ...alts('Rdotbelow'),
            ...alts('Rdotbelowmacron'),
            ...alts('Rdoublegrave'),
            ...alts('Rinvertedbreve'),
            ...alts('Sacute'),
            ...alts('Scaron'),
            ...alts('Scedilla'),
            ...alts('uni015E'),
            ...alts('Scircumflex'),
            ...alts('Scommaaccent', { add_dot_round: true }),
            ...alts('uni0218'), // Scommabelow
            ...alts('Sdotbelow'),
            ...alts('Tbar'),
            ...alts('Tcaron'),
            ...alts('Tcedilla'),
            ...alts('uni0162'),
            ...alts('Tcommaaccent', { add_dot_round: true }),
            ...alts('Tdotbelow'),
            ...alts('uni021A', { add_dot_round: true }),
            ...alts('Uacute'),
            ...alts('Ubreve'),
            ...alts('Ucaron'),
            ...alts('Ucircumflex'),
            ...alts('Udieresis', { add_dot_i: true, add_dot_round: true }),
            'Uumlaut.alt',
            ...alts('uni01D9'),
            ...alts('Udotbelow', { add_dot_round: true }),
            'Uhorndotbelow.round',
            ...alts('Udoublegrave'),
            ...alts('Ugrave'),
            ...alts('Uhungarumlaut'),
            ...alts('Uinvertedbreve'),
            ...alts('Umacron'),
            ...alts('Umacronbelow'),
            ...alts('Uogonek'),
            ...alts('Uring'),
            ...alts('Utilde'),
            ...alts('Wacute'),
            'Wacute.editalt.alt',
            'Wacute.brandalt',
            ...alts('Wcircumflex'),
            'Wcircumflex.editalt.alt',
            'Wcircumflex.brandalt',
            ...alts('Wdieresis', { add_dot_i: true, add_dot_round: true }),
            'Wdieresis.editalt.alt',
            'Wdieresis.brandalt',
            ...alts('Wgrave'),
            'Wgrave.editalt.alt',
            'Wgrave.brandalt',
            ...alts('Yacute'),
            ...alts('Ycircumflex'),
            ...alts('Ydieresis', { add_dot_i: true, add_dot_round: true }),
            'Ydotbelow.round',
            ...alts('Ygrave'),
            ...alts('Ymacron'),
            ...alts('Ytilde'),
            ...alts('Zacute'),
            ...alts('Zcaron'),
            ...alts('Zdotaccent', { add_dot_i: true, add_dot_round: true }),
            ...alts('Thorn'),
            ...alts('Schwa'),
            ...alts('Ezh'),
            ...alts('Ezhcaron'),
        ],
    },
    {
        title: 'Lowercase accents',
        orderedGlyphNames: [
            'aacute',
            ...vietAlts('abreve'),
            'acaron',
            ...vietAlts('acircumflex'),
            'adieresis',
            'adieresismacron',
            'adotabove',
            'adotbelow',
            'adoublegrave',
            'agrave',
            'ahoi',
            'ainvertedbreve',
            'amacron',
            'aogonek',
            'aring',
            'aringacute',
            'atilde',
            'ae',
            'aeacute',
            'aemacron',
            'cacute',
            'ccaron',
            'ccedilla',
            'ccircumflex',
            'cdotaccent',
            'dcaron',
            'dcedilla',
            'dcroat',
            'ddotbelow',
            'eacute',
            'edotbelowacute',
            'uni006500B40323', // eacutedotbelow
            'ebreve',
            'ecaron',
            ...vietAlts('ecircumflex'),
            'edieresis',
            'edotaccent',
            'edotbelow',
            'uni1EB9', // edotbelow
            'edoublegrave',
            'egrave',
            'ehoi',
            'uni006500600323', // egravedotbelow
            'einvertedbreve',
            'emacron',
            'eogonek',
            'etilde',
            'eth',
            'gacute',
            'gbreve',
            'gcaron',
            'gcircumflex',
            'gcommaaccent',
            'gcommaturnedabove',
            'uni0123', // gcommabelow
            'gdotaccent',
            'gstroke',
            'gtilde',
            'hbar',
            'hcaron',
            'hcircumflex',
            'hdotbelow',
            'hmacronbelow',
            'iacute',
            'ibreve',
            'icaron',
            'icircumflex',
            'idieresis',
            'idotaccent',
            'idotbelow',
            'idoublegrave',
            'igrave',
            'ihoi',
            'iinvertedbreve',
            'dotlessi',
            'ij',
            'ijacute',
            'imacron',
            'iogonek',
            'itilde',
            'jacute',
            'jcaron',
            'jcircumflex',
            'kcaron',
            'kcedilla',
            'kcommaaccent',
            'uni0137', // kcommabelow
            'kgreenlandic',
            'khook',
            'lacute',
            'lcaron',
            'lcedilla',
            'lcommaaccent',
            'uni013C', // lcommabelow
            'ldot',
            'ldotbelow',
            'uni1E37', // ldotbelow
            'ldotbelowmacron',
            'uni1E39', // lmacrondotbelow
            'lslash',
            'mcedilla',
            'mdotbelow',
            'mmacron',
            'nacute',
            'ncaron',
            'ncedilla',
            'ncommaaccent',
            'uni0146', // ncommabelow
            'ndescender',
            'ndieresis',
            'ndotaccent',
            'ndotbelow',
            'ngrave',
            'nmacron',
            'ntilde',
            'eng',
            'oacute',
            'obreve',
            'ocaron',
            'ocedilla',
            'uni01ED', // ocedillamacron
            ...vietAlts('ocircumflex'),
            'ocommaturnedabove',
            'odieresis',
            'odotabove',
            'odotabovemacron',
            'uni0231', // omacrondotabove
            'odotbelow',
            'odoublegrave',
            'oe',
            'ograve',
            'ohoi',
            ...vietAlts('ohorn'),
            'ohungarumlaut',
            'oinvertedbreve',
            'omacron',
            'uni006F0329', // olinebelow
            'oogonek',
            'oogonekmacron',
            'oslash',
            'oslashacute',
            'otilde',
            'otildemacron',
            'ounderlineaccent',
            'barredo',
            'pmacron',
            'racute',
            'rcaron',
            'rcedilla',
            'rcommaaccent',
            'uni0157', // rcommabelow
            'rdotbelow',
            'rdotbelowmacron',
            'rdoublegrave',
            'sacute',
            'scaron',
            'scedilla',
            'uni015F', // scedilla
            'sdotbelow',
            'uni0219', // scommabelow
            'scircumflex',
            'scommaaccent',
            'germandbls',
            'tbar',
            'tcaron',
            'tcedilla',
            'uni0163', // tcedilla
            'tcommaaccent',
            'tdotbelow',
            'uni021B', // tcommabelow
            'uacute',
            'ubreve',
            'ucaron',
            'ucircumflex',
            'udieresis',
            'uni01DA', // udieresiscaron
            'udotbelow',
            'udoublegrave',
            'ugrave',
            'uhoi',
            ...vietAlts('uhorn'),
            'uhungarumlaut',
            'uinvertedbreve',
            'umacron',
            'umacronbelow',
            'uogonek',
            'uring',
            'utilde',
            'wacute',
            'wcircumflex',
            'wdieresis',
            'wgrave',
            'yacute',
            'ycircumflex',
            'ydieresis',
            'ygrave',
            'yhoi',
            'ymacron',
            'ydotbelow',
            'ytilde',
            'zacute',
            'zcaron',
            'zdotaccent',
            'thorn',
            'ezh',
            'ezhcaron',
            'schwa',
        ],
    },
    {
        title: 'Lowercase accent alternates',
        orderedGlyphNames: [
            ...alts('aacute', { add_dot_school: true }),
            ...alts('abreve', { add_dot_school: true }),
            'abrevedotbelow.round',
            ...vietAlts('abreve', '.1'),
            'acaron.school',
            ...alts('acircumflex', { add_dot_school: true }),
            'acircumflexdotbelow.round',
            ...vietAlts('acircumflex', '.1'),
            ...alts('adieresis', { add_dot_school: true, add_dot_i: true }),
            'adieresis.1.i',
            'adieresismacron.school',
            'adotabove.school',
            ...alts('adotbelow', { add_dot_round: true }),
            'adoublegrave.school',
            ...alts('agrave', { add_dot_school: true }),
            ...alts('ahoi'),
            'ainvertedbreve.school',
            ...alts('amacron', { add_dot_school: true }),
            ...alts('aogonek', { add_dot_school: true }),
            ...alts('aring', { add_dot_school: true }),
            ...alts('aringacute', { add_dot_school: true }),
            ...alts('atilde', { add_dot_school: true }),
            ...alts('ae'),
            'ae.2',
            ...alts('aeacute'),
            'aeacute.2',
            ...alts('adieresis', { add_dot_round: true }),
            'ccedilla.1',
            'cacute.1',
            'ccircumflex.1',
            ...alts('cdotaccent', { add_dot_round: true }),
            'ccaron.1',
            'cdotaccent.i',
            'dcaron.1',
            ...alts('egrave'),
            ...alts('eacute'),
            ...alts('ecircumflex'),
            'ecircumflexdotbelow.round',
            'edotbelow.round',
            ...alts('edieresis', { add_dot_round: true, add_dot_i: true }),
            ...alts('emacron'),
            ...alts('ebreve'),
            ...alts('edotaccent', { add_dot_round: true, add_dot_i: true }),
            ...alts('eogonek'),
            ...alts('ecaron'),
            ...alts('gcommaaccent', {
                add_dot_round: true,
                add_dot_school: true,
            }),
            'gcommaaccent.2',
            'gcommaaccent.3',
            'gcaron.school',
            ...alts('gcircumflex', { add_dot_school: true }),
            'gcircumflex.2',
            'gcircumflex.3',
            'gacute.school',
            ...alts('gbreve', { add_dot_school: true }),
            'gbreve.2',
            'gbreve.3',
            ...alts('gdotaccent', {
                add_dot_round: true,
                add_dot_school: true,
                add_dot_i: true,
            }),
            'gdotaccent.1.i',
            'gdotaccent.2',
            'gdotaccent.2.i',
            'gdotaccent.3',
            'gdotaccent.3.i',
            'gcommaturnedabove.school',
            'uni0123.1', // gcommabelow
            'gstroke.school',
            'gtilde.school',
            'uni0123.alt', // gcommabelow
            'hbar.1',
            'hcircumflex.1',
            'ij.i',
            'ij.round',
            'idieresis.i',
            'idieresis.round',
            'idotaccent.round',
            'idotbelow.round',
            'iogonek.i',
            'iogonek.round',
            'kcommaaccent.1',
            'kcommaaccent.round',
            'lcaron.1',
            'ldot.i',
            'ldot.round',
            'lcommaaccent.round',
            'nacute.1',
            'nacute.round',
            'ncommaaccent.1',
            'ncaron.1',
            'ntilde.1',
            'eng.1',
            'odotbelow.round',
            'ocircumflexdotbelow.round',
            'ohorndotbelow.round',
            'odieresis.i',
            'odieresis.round',
            'oe.alt',
            'racute.1',
            'racute.2',
            'rcaron.1',
            'rcaron.2',
            'rcommaaccent.round',
            'rcommaaccent.1',
            'rcommaaccent.2',
            ...alts('scedilla'),
            ...alts('scommaaccent', { add_dot_round: true }),
            ...alts('sacute'),
            ...alts('scircumflex'),
            ...alts('uni015F'),
            ...alts('scaron'),
            ...alts('uni0219'),
            'germandbls.1',
            'tcommaaccent.alt',
            ...alts('uni021B', { add_dot_round: true }), // tcommaaccent
            'tcaron.alt',
            'tbar.alt',
            'oe.1',
            ...alts('uacute'),
            ...alts('ubreve'),
            ...alts('ucircumflex'),
            ...alts('udieresis', { add_dot_i: true, add_dot_round: true }),
            'udieresis.1.i',
            'udotbelow.round',
            'uhorndotbelow.round',
            ...alts('ugrave'),
            ...alts('uhungarumlaut'),
            ...alts('umacron'),
            ...alts('uogonek'),
            ...alts('uring'),
            ...alts('utilde'),
            ...alts('wcircumflex'),
            ...alts('wgrave'),
            ...alts('wacute'),
            ...alts('wdieresis', { add_dot_i: true, add_dot_round: true }),
            ...alts('yacute', { add_dot_school: true }),
            ...alts('ydieresis', {
                add_dot_i: true,
                add_dot_school: true,
                add_dot_round: true,
            }),
            ...alts('ycircumflex', { add_dot_school: true }),
            ...alts('ygrave', { add_dot_school: true }),
            'ydotbelow.round',
            'ymacron.school',
            'ytilde.school',
            'zacute.1',
            ...alts('zdotaccent', { add_dot_i: true, add_dot_round: true }),
            'zcaron.1',
        ],
    },
    {
        title: 'Small capital accents',
        orderedGlyphNames: [
            // copied the main Uppercase accents list and appended .sc
            'Aacute.sc',
            ...vietAlts('Abreve', '.sc'),
            'Acaron.sc',
            ...vietAlts('Acircumflex', '.sc'),
            'Adieresis.sc',
            'Adieresismacron.sc',
            'uni004100A800AF.sc',
            'Adotabove.sc',
            'Adotbelow.sc',
            'Adoublegrave.sc',
            'Agrave.sc',
            'Ahoi.sc',
            'Ainvertedbreve.sc',
            'Amacron.sc',
            'Anocross.sc',
            'Aogonek.sc',
            'Aring.sc',
            'Aringacute.sc',
            'uni004102DA00B4.sc',
            'Atilde.sc',
            'AE.sc',
            'AEacute.sc',
            'AEmacron.sc',
            'Cacute.sc',
            'Ccaron.sc',
            'Ccedilla.sc',
            'Ccircumflex.sc',
            'Cdotaccent.sc',
            'Dcaron.sc',
            'Dcedilla.sc',
            'Ddotbelow.sc',
            'Dcroat.sc',
            'Eacute.sc',
            'Edotbelowacute.sc',
            'uni004500B40323.sc',
            'Ebreve.sc',
            'Ecaron.sc',
            ...vietAlts('Ecircumflex', '.sc'),
            'Edieresis.sc',
            'Edotaccent.sc',
            'Edotbelow.sc',
            'uni1EB8.sc', // Edotbelow.sc
            'Edoublegrave.sc',
            'Egrave.sc',
            'Ehoi.sc',
            'uni004500600323.sc',
            'Einvertedbreve.sc',
            'Emacron.sc',
            'Eogonek.sc',
            'Etilde.sc',
            'Eth.sc',
            'Gacute.sc',
            'Gbreve.sc',
            'Gcaron.sc',
            'Gcircumflex.sc',
            'Gcommaaccent.sc',
            'Gcommaturnedabove.sc',
            'uni0122.sc',
            'Gdotaccent.sc',
            'Gstroke.sc',
            'Gtilde.sc',
            'Hbar.sc',
            'Hcaron.sc',
            'Hcircumflex.sc',
            'Hdotbelow.sc',
            'Hmacronbelow.sc',
            'IJ.sc',
            'IJacute.sc',
            'Iacute.sc',
            'Ibreve.sc',
            'Icaron.sc',
            'Icircumflex.sc',
            'Idieresis.sc',
            'Idotaccent.sc',
            'Idotbelow.sc',
            'Idoublegrave.sc',
            'Igrave.sc',
            'Ihoi.sc',
            'Iinvertedbreve.sc',
            'Imacron.sc',
            'Iogonek.sc',
            'Itilde.sc',
            'dotlessi.sc',
            'Jacute.sc',
            'Jcaron.sc',
            'Jcircumflex.sc',
            'Kcaron.sc',
            'Kcedilla.sc',
            'Kcommaaccent.sc',
            'uni0136.sc',
            'Khook.sc',
            'Lacute.sc',
            'Lcaron.sc',
            'Lcedilla.sc',
            'Lcommaaccent.sc',
            'uni013B.sc',
            'Ldot.sc',
            'Ldotbelow.sc',
            'uni1E36.sc',
            'uni1E38.sc',
            'uni004C032300AF.sc',
            'Lslash.sc',
            'Mcedilla.sc',
            'Mdotbelow.sc',
            'Mmacron.sc',
            'Nacute.sc',
            'Ncaron.sc',
            'Ncedilla.sc',
            'Ncommaaccent.sc',
            'uni0145.sc',
            'Ndescender.sc',
            'Ndieresis.sc',
            'Ndotaccent.sc',
            'Ndotbelow.sc',
            'Ngrave.sc',
            'Nmacron.sc',
            'Ntilde.sc',
            'Eng.sc',
            'Oacute.sc',
            'Obreve.sc',
            'Ocaron.sc',
            'Ocedilla.sc',
            'uni01EC.sc',
            'uni004F02DB00AF.sc',
            ...vietAlts('Ocircumflex', '.sc'),
            'Ocommaturnedabove.sc',
            'Odieresis.sc',
            'Odotabove.sc',
            'uni004F02D900AF.sc',
            'uni004F0329.sc',
            'Odotbelow.sc',
            'Odoublegrave.sc',
            'Ograve.sc',
            'Ohoi.sc',
            ...vietAlts('Ohorn', '.sc'),
            'Ohungarumlaut.sc',
            'Oinvertedbreve.sc',
            'Omacron.sc',
            'uni0230.sc',
            'Oogonek.sc',
            'Oslash.sc',
            'Oslashacute.sc',
            'Otilde.sc',
            'Otildemacron.sc',
            'Ounderlineaccent.sc',
            'barredO.sc',
            'OE.sc',
            'Pmacron.sc',
            'Racute.sc',
            'Rcaron.sc',
            'Rcedilla.sc',
            'Rcommaaccent.sc',
            'uni0156.sc',
            'Rdotbelow.sc',
            'Rdotbelowmacron.sc',
            'Rdoublegrave.sc',
            'Rinvertedbreve.sc',
            'Sacute.sc',
            'Scaron.sc',
            'Scedilla.sc',
            'uni015E.sc',
            'Scircumflex.sc',
            'Scommaaccent.sc',
            'uni0218.sc',
            'Sdotbelow.sc',
            'Germandbls.sc',
            'Tbar.sc',
            'Tcaron.sc',
            'Tcedilla.sc',
            'uni0162.sc',
            'Tcommaaccent.sc',
            'Tdotbelow.sc',
            'uni021A.sc',
            'Uacute.sc',
            'Ubreve.sc',
            'Ucaron.sc',
            'Ucircumflex.sc',
            'Udieresis.sc',
            'uni01D9.sc',
            'Udotbelow.sc',
            'Udoublegrave.sc',
            'Ugrave.sc',
            'Uhoi.sc',
            ...vietAlts('Uhorn', '.sc'),
            'Uhungarumlaut.sc',
            'Uinvertedbreve.sc',
            'Umacron.sc',
            'Umacronbelow.sc',
            'Uogonek.sc',
            'Uring.sc',
            'Utilde.sc',
            'Wacute.sc',
            'Wcircumflex.sc',
            'Wdieresis.sc',
            'Wgrave.sc',
            'Yacute.sc',
            'Ycircumflex.sc',
            'Ydieresis.sc',
            'Ydotbelow.sc',
            'Ygrave.sc',
            'Yhoi.sc',
            'Ymacron.sc',
            'Ytilde.sc',
            'Zacute.sc',
            'Zcaron.sc',
            'Zdotaccent.sc',
            'Thorn.sc',
            'Schwa.sc',
            'Ezh.sc',
            'Ezhcaron.sc',
            // copied the main Uppercase accents list, converted to lowercase and appended .smcp
            'aacute.smcp',
            'abreve.smcp',
            'acaron.smcp',
            'acircumflex.smcp',
            'adieresis.smcp',
            'adieresismacron.smcp',
            'uni004100a800af.smcp',
            'adotabove.smcp',
            'adoublegrave.smcp',
            'agrave.smcp',
            'ainvertedbreve.smcp',
            'amacron.smcp',
            'anocross.smcp',
            'aogonek.smcp',
            'aring.smcp',
            'aringacute.smcp',
            'uni004102da00b4.smcp',
            'atilde.smcp',
            'ae.smcp',
            'aeacute.smcp',
            'aemacron.smcp',
            'cacute.smcp',
            'ccaron.smcp',
            'ccedilla.smcp',
            'ccircumflex.smcp',
            'cdotaccent.smcp',
            'dcaron.smcp',
            'dcedilla.smcp',
            'ddotbelow.smcp',
            'dcroat.smcp',
            'eacute.smcp',
            'uni006500600323.smcp', // eacutedotbelow
            'ebreve.smcp',
            'ecaron.smcp',
            'ecircumflex.smcp',
            'edieresis.smcp',
            'edotaccent.smcp',
            'edoublegrave.smcp',
            'egrave.smcp',
            'uni004500b40323.smcp', // egravedotbelow
            'uni006500B40323.smcp', // egravedotbelow
            'uni004500600323.smcp',
            'einvertedbreve.smcp',
            'emacron.smcp',
            'eogonek.smcp',
            'eth.smcp',
            'gacute.smcp',
            'gbreve.smcp',
            'gcaron.smcp',
            'gcircumflex.smcp',
            'gcommaaccent.smcp',
            'gcommaturnedabove.smcp',
            'uni0122.smcp',
            'gdotaccent.smcp',
            'gstroke.smcp',
            'gtilde.smcp',
            'hbar.smcp',
            'hcaron.smcp',
            'hcircumflex.smcp',
            'hdotbelow.smcp',
            'hmacronbelow.smcp',
            'ij.smcp',
            'iacute.smcp',
            'ibreve.smcp',
            'icaron.smcp',
            'icircumflex.smcp',
            'idieresis.smcp',
            'idotaccent.smcp',
            'idotbelow.smcp',
            'idoublegrave.smcp',
            'igrave.smcp',
            'iinvertedbreve.smcp',
            'imacron.smcp',
            'iogonek.smcp',
            'itilde.smcp',
            'jcaron.smcp',
            'jcircumflex.smcp',
            'kcaron.smcp',
            'kcedilla.smcp',
            'kcommaaccent.smcp',
            'uni0136.smcp',
            'khook.smcp',
            'lacute.smcp',
            'lcaron.smcp',
            'lcedilla.smcp',
            'lcommaaccent.smcp',
            'uni013b.smcp',
            'ldot.smcp',
            'ldotbelow.smcp',
            'uni1E37.smcp', // ldotbelow
            'ldotbelowmacron.smcp',
            'uni1E39.smcp', // lmacrondotbelow
            'uni1e36.smcp',
            'uni1e38.smcp',
            'uni004c032300af.smcp',
            'lslash.smcp',
            'mcedilla.smcp',
            'mdotbelow.smcp',
            'mmacron.smcp',
            'nacute.smcp',
            'ncaron.smcp',
            'ncedilla.smcp',
            'ncommaaccent.smcp',
            'uni0145.smcp',
            'ndescender.smcp',
            'ndieresis.smcp',
            'ndotaccent.smcp',
            'ndotbelow.smcp',
            'ngrave.smcp',
            'nmacron.smcp',
            'ntilde.smcp',
            'eng.smcp',
            'oacute.smcp',
            'obreve.smcp',
            'ocaron.smcp',
            'ocedilla.smcp',
            'uni01ED.smcp', // ocedillamacron
            'uni01ec.smcp',
            'uni004f02db00af.smcp',
            'ocircumflex.smcp',
            'ocommaturnedabove.smcp',
            'odieresis.smcp',
            'odotabove.smcp',
            'odotabovemacron.smcp',
            'uni0231.smcp', // omacrondotabove
            'uni004f02d900af.smcp',
            'uni004f0329.smcp',
            'odotbelow.smcp',
            'odoublegrave.smcp',
            'ograve.smcp',
            'ohungarumlaut.smcp',
            'oinvertedbreve.smcp',
            'omacron.smcp',
            'uni006F0329.smcp', // olinebelow
            'uni0230.smcp',
            'oogonek.smcp',
            'oogonekmacron.smcp',
            'oslash.smcp',
            'oslashacute.smcp',
            'otilde.smcp',
            'otildemacron.smcp',
            'ounderlineaccent.smcp',
            'barredo.smcp',
            'oe.smcp',
            'pmacron.smcp',
            'racute.smcp',
            'rcaron.smcp',
            'rcedilla.smcp',
            'rcommaaccent.smcp',
            'uni0156.smcp',
            'rdotbelow.smcp',
            'rdotbelowmacron.smcp',
            'rdoublegrave.smcp',
            'rinvertedbreve.smcp',
            'sacute.smcp',
            'scaron.smcp',
            'scedilla.smcp',
            'uni015e.smcp',
            'scircumflex.smcp',
            'scommaaccent.smcp',
            'germandbls.smcp',
            'uni0218.smcp',
            'sdotbelow.smcp',
            'tbar.smcp',
            'tcaron.smcp',
            'tcedilla.smcp',
            'uni0162.smcp',
            'tcommaaccent.smcp',
            'tdotbelow.smcp',
            'uni021B.smcp', // tcommabelow
            'uni021a.smcp',
            'uacute.smcp',
            'ubreve.smcp',
            'ucaron.smcp',
            'ucircumflex.smcp',
            'udieresis.smcp',
            'uni01DA.smcp', // udieresiscaron
            'uni01d9.smcp',
            'udotbelow.smcp',
            'udoublegrave.smcp',
            'ugrave.smcp',
            'uhungarumlaut.smcp',
            'uinvertedbreve.smcp',
            'umacron.smcp',
            'umacronbelow.smcp',
            'uogonek.smcp',
            'uring.smcp',
            'utilde.smcp',
            'wacute.smcp',
            'wcircumflex.smcp',
            'wdieresis.smcp',
            'wgrave.smcp',
            'yacute.smcp',
            'ycircumflex.smcp',
            'ydieresis.smcp',
            'ygrave.smcp',
            'ymacron.smcp',
            'ytilde.smcp',
            'zacute.smcp',
            'zcaron.smcp',
            'zdotaccent.smcp',
            'thorn.smcp',
            'schwa.smcp',
            'ezh.smcp',
            'ezhcaron.smcp',
        ],
    },
    {
        title: 'Small capital accent alternates',
        orderedGlyphNames: [
            'Dcaron.1sc',
            'Dcaron.1.sc',
            'Eth.1sc',
            'Eth.1.sc',
            'Dcroat.1sc',
            'Dcroat.1.sc',
            'Racute.1sc',
            'Racute.1.sc',
            'uni0156.1sc', // Rcommabelow
            'Rcommaaccent.1.sc',
            'Rcaron.1sc',
            'Rcaron.1.sc',
            //
            'racute_alt.smcp',
            'rcaron_alt.smcp',
            'rinvertedbreve_alt.smcp',
            'rdoublegrave_alt.smcp',
            'rcommaaccent_alt.smcp',
            'rcedilla_alt.smcp',
            'rdotbelow_alt.smcp',
            'rdotbelowmacron_alt.smcp',
        ],
    },
    {
        title: 'Numerals',
        orderedGlyphNames: numSet(),
    },
    {
        title: 'Numeral alternates',
        orderedGlyphNames: [
            'zero.slash',
            'zero.alt',
            'one.alt',
            'one.1',
            'one.2',
            'two.alt',
            'three.alt',
            'four.alt',
        ],
    },
    {
        title: 'Lining numerals',
        orderedGlyphNames: numSet('.LP'),
    },
    {
        title: 'Lining numeral alternates',
        orderedGlyphNames: ['zerolp.slash', 'zeroLP.slash'],
    },
    {
        title: 'Old-style numerals',
        orderedGlyphNames: [...numSet('.op'), ...numSet('.OP')],
    },
    {
        title: 'Old-style numeral alternates',
        orderedGlyphNames: [
            'zero_slash.op',
            'zeroop.slash',
            'zeroOP.slash',
            'zero.slash.op',
            'one.op.alt',
        ],
    },
    {
        title: 'Small capital numerals',
        orderedGlyphNames: [...numSet('.sc'), ...numSet('.XP')],
    },
    // {
    //     title: 'Small capital numeral alternates',
    // },
    {
        title: 'Tabular lining numerals',
        orderedGlyphNames: [...numSet('.lt'), ...numSet('.LT')],
    },
    {
        title: 'Tabular lining numeral alternates',
        orderedGlyphNames: [
            'zero_slash.lt',
            'zeroLT.slash',
            'zero.slashlt',
            'zero.slash.lt',
            'one.lt.alt',
            'one.lt_brand',
            'one.1.lt',
            'one.2.lt',
            'two.lt.alt',
            'three.lt.alt',
            'four.lt.alt',
        ],
    },
    {
        title: 'Tabular old-style numerals',
        orderedGlyphNames: [...numSet('.ot'), ...numSet('.OT')],
    },
    {
        title: 'Tabular old-style numeral alternates',
        orderedGlyphNames: [
            'zero.ot.alt',
            'one.ot.alt',
            'zero_slash.ot',
            'zero.slash.ot',
            'zeroOT.slash',
        ],
    },
    {
        title: 'Tabular small capital numerals',
        orderedGlyphNames: numSet('.st'),
    },
    {
        title: 'Currency & math',
        orderedGlyphNames: [
            'dollar',
            'cent',
            'sterling',
            'yen',
            'baht',
            'uni0E3F', // baht
            'uni20A1', // COLON
            'uni20A2', // CRUZEIRO
            'franc',
            'uni20BD', // ruble
            'lira',
            'uni20BA', // lira
            'naira',
            'uni20A6', // NAIRA
            'peseta',
            'uni20A8', // rupee
            'won',
            'uni20A9', // won
            'uni20AA', // NEW SHEQEL
            'Euro',
            'uni20B9', // INDIAN RUPEE
            'uni20AE', // TUGRIK
            'peso',
            'uni20B1', // Peso
            'dong',
            'florin',
            'uni0192', // florin
            //
            'plus',
            'minus',
            'plusminus',
            'divide',
            'multiply',
            'equal',
            'less',
            'greater',
            'lessequal',
            'greaterequal',
            'approxequal',
            'notequal',
            'logicalnot',
            //
            'numbersign',
            'percent',
            'perthousand',
        ],
    },
    {
        title: 'Currency & math alternates',
        orderedGlyphNames: ['divide.i', 'divide.round'],
    },
    {
        title: 'Old-style currency & math',
        orderedGlyphNames: [
            'dollar.op',
            'cent.op',
            'sterling.op',
            'yen.op',
            'baht.op',
            'uni0E3F.op', // baht
            'uni20A1.op', // COLON
            'uni20A2.op', // CRUZEIRO
            'franc.op',
            'uni20BD.op', // ruble
            'lira.op',
            'uni20BA.op', // lira
            'naira.op',
            'uni20A6.op', // NAIRA
            'peseta.op',
            'uni20A8.op', // rupee
            'won.op',
            'uni20A9.op', // won
            'uni20AA.op', // NEW SHEQEL
            'Euro.op',
            'uni20B9.op', // INDIAN RUPEE
            'uni20AE.op', // TUGRIK
            'peso.op',
            'uni20B1.op', // Peso
            'dong.op',
            'florin.op',
            'uni0192.op', // florin
            //
            'plus.op',
            'minus.op',
            'plusminus.op',
            'divide.op',
            'multiply.op',
            'equal.op',
            'less.op',
            'greater.op',
            'lessequal.op',
            'greaterequal.op',
            'approxequal.op',
            'notequal.op',
            'logicalnot.op',
            //
            'percent.op',
            'perthousand.op',
        ],
    },
    {
        title: 'Old-style currency & math alternates',
        orderedGlyphNames: ['divide.op.i'],
    },
    {
        title: 'Lining currency & math',
        orderedGlyphNames: [
            'dollar.LP',
            'cent.LP',
            'sterling.LP',
            'yen.LP',
            'baht.LP',
            'uni0E3F.LP', // baht
            'uni20A1.LP', // COLON
            'uni20A2.LP', // CRUZEIRO
            'franc.LP',
            'uni20A3.LP', // franc
            'uni20BD.LP', // ruble
            'lira.LP',
            'uni20BA.LP', // lira
            'uni20A4.LP', // lira
            'naira.LP',
            'uni20A6.LP', // NAIRA
            'peseta.LP',
            'uni20A7.LP', // peseta
            'uni20A8.LP', // rupee
            'won.LP',
            'uni20A9.LP', // won
            'uni20AA.LP', // NEW SHEQEL
            'Euro.LP',
            'uni20B9.LP', // INDIAN RUPEE
            'uni20AE.LP', // TUGRIK
            'peso.LP',
            'uni20B1.LP', // Peso
            'dong.LP',
            'florin.LP',
            'uni0192.LP', // florin
            //
            'plus.LP',
            'minus.LP',
            'plusminus.LP',
            'divide.LP',
            'multiply.LP',
            'equal.LP',
            'less.LP',
            'greater.LP',
            'lessequal.LP',
            'greaterequal.LP',
            'approxequal.LP',
            'notequal.LP',
            'logicalnot.LP',
            //
            'plus.cap',
            'minus.cap',
            'plusminus.cap',
            'divide.cap',
            'multiply.cap',
            'equal.cap',
            'less.cap',
            'greater.cap',
            'lessequal.cap',
            'greaterequal.cap',
            'approxequal.cap',
            'notequal.cap',
            'logicalnot.cap',
            //
            'numbersign.LP',
            'percent.LP',
            'perthousand.LP',
        ],
    },
    {
        title: 'Small capital currency & math',
        orderedGlyphNames: [
            'dollar.sc',
            'cent.sc',
            'sterling.sc',
            'yen.sc',
            'baht.sc',
            'uni0E3F.sc', // baht
            'uni20A1.sc', // COLON
            'uni20A2.sc', // CRUZEIRO
            'franc.sc',
            'uni20BD.sc', // ruble
            'lira.sc',
            'uni20BA.sc', // lira
            'naira.sc',
            'uni20A6.sc', // NAIRA
            'peseta.sc',
            'uni20A8.sc', // rupee
            'won.sc',
            'uni20A9.sc', // won
            'uni20AA.sc', // NEW SHEQEL
            'Euro.sc',
            'uni20B9.sc', // INDIAN RUPEE
            'uni20AE.sc', // TUGRIK
            'peso.sc',
            'uni20B1.sc', // Peso
            'dong.sc',
            'florin.sc',
            'uni0192.sc', // florin
            //
            'plus.sc',
            'minus.sc',
            'plusminus.sc',
            'divide.sc',
            'multiply.sc',
            'equal.sc',
            'less.sc',
            'greater.sc',
            'lessequal.sc',
            'greaterequal.sc',
            'approxequal.sc',
            'notequal.sc',
            'logicalnot.sc',
        ],
    },
    {
        title: 'Tabular old-style currency & math',
        orderedGlyphNames: [
            'dollar.ot',
            'cent.ot',
            'sterling.ot',
            'yen.ot',
            'baht.ot',
            'uni0E3F.ot', // baht
            'uni20A1.ot', // COLON
            'uni20A2.ot', // CRUZEIRO
            'franc.ot',
            'uni20BD.ot', // ruble
            'lira.ot',
            'uni20BA.ot', // lira
            'naira.ot',
            'uni20A6.ot', // NAIRA
            'peseta.ot',
            'uni20A8.ot', // rupee
            'won.ot',
            'uni20A9.ot', // won
            'uni20AA.ot', // NEW SHEQEL
            'Euro.ot',
            'uni20B9.ot', // INDIAN RUPEE
            'uni20AE.ot', // TUGRIK
            'peso.ot',
            'uni20B1.ot', // Peso
            'dong.ot',
            'florin.ot',
            'uni0192.ot', // florin
            //
            'plus.ot',
            'minus.ot',
            'plusminus.ot',
            'divide.ot',
            'multiply.ot',
            'equal.ot',
            'less.ot',
            'greater.ot',
            'lessequal.ot',
            'greaterequal.ot',
            'approxequal.ot',
            'notequal.ot',
            'logicalnot.ot',
            //
            'percent.ot',
            'perthousand.ot',
        ],
    },
    {
        title: 'Tabular lining currency & math',
        orderedGlyphNames: [
            'dollar.lt',
            'cent.lt',
            'sterling.lt',
            'yen.lt',
            'baht.lt',
            'uni0E3F.lt', // baht
            'uni20A1.lt', // COLON
            'uni20A2.lt', // CRUZEIRO
            'franc.lt',
            'uni20BD.lt', // ruble
            'lira.lt',
            'uni20BA.lt', // lira
            'naira.lt',
            'uni20A6.lt', // NAIRA
            'peseta.lt',
            'uni20A8.lt', // rupee
            'won.lt',
            'uni20A9.lt', // won
            'uni20AA.lt', // NEW SHEQEL
            'Euro.lt',
            'uni20B9.lt', // INDIAN RUPEE
            'uni20AE.lt', // TUGRIK
            'peso.lt',
            'uni20B1.lt', // Peso
            'dong.lt',
            'florin.lt',
            'uni0192.lt', // florin
            //
            'plus.lt',
            'plus.tab',
            'minus.lt',
            'minus.tab',
            'plusminus.lt',
            'divide.lt',
            'divide.tab',
            'multiply.lt',
            'multiply.tab',
            'equal.lt',
            'equal.tab',
            'less.lt',
            'less.tab',
            'greater.lt',
            'greater.tab',
            'lessequal.lt',
            'greaterequal.lt',
            'approxequal.lt',
            'notequal.lt',
            'logicalnot.lt',
            //
            'percent.lt',
            'perthousand.lt',
        ],
    },
    {
        title: 'Tabular lining currency & math alternates',
        orderedGlyphNames: ['divide.round.tab'],
    },
    {
        title: 'Tabular small capital currency & math',
        orderedGlyphNames: [
            'dollar.st',
            'cent.st',
            'sterling.st',
            'yen.st',
            'baht.st',
            'uni0E3F.st', // baht
            'uni20A1.st', // COLON
            'uni20A2.st', // CRUZEIRO
            'franc.st',
            'uni20BD.st', // ruble
            'lira.st',
            'uni20BA.st', // lira
            'naira.st',
            'uni20A6.st', // NAIRA
            'peseta.st',
            'uni20A8.st', // rupee
            'won.st',
            'uni20A9.st', // won
            'uni20AA.st', // NEW SHEQEL
            'Euro.st',
            'uni20B9.st', // INDIAN RUPEE
            'uni20AE.st', // TUGRIK
            'peso.st',
            'uni20B1.st', // Peso
            'dong.st',
            'florin.st',
            'uni0192.st', // florin
            //
            'plus.st',
            'minus.st',
            'plusminus.st',
            'divide.st',
            'multiply.st',
            'equal.st',
            'less.st',
            'greater.st',
            'lessequal.st',
            'greaterequal.st',
            'approxequal.st',
            'notequal.st',
            'logicalnot.st',
            //
            'percent.st',
            'perthousand.st',
        ],
    },
    {
        title: 'Superscript',
        orderedGlyphNames: [
            'uni2070', // zerosuperior
            'onesuperior',
            'uni00B9', // onesuperior
            'twosuperior',
            'uni00B2', // twosuperior
            'threesuperior',
            'uni00B3', // threesuperior
            'uni2074', // foursuperior
            'uni2075', // fivesuperior
            'uni2076', // sixsuperior
            'uni2077', // sevensuperior
            'uni2078', // eightsuperior
            'uni2079', // ninesuperior
            'uni207D', // parenleft superscript
            'uni207E', // parenright superscript
            'uni207A', // plus superscript
            'uni207B', // minus superscript
            'uni207C', // equal superscript
        ],
    },
    {
        title: 'Superscript alternates',
        orderedGlyphNames: [
            'onesuperior.alt',
            'twosuperior.alt',
            'threesuperior.alt',
        ],
    },
    {
        title: 'Denominator',
        orderedGlyphNames: numSet('.dnom'),
    },
    {
        title: 'Subscript',
        orderedGlyphNames: [
            'uni2080', // zero subscript
            'uni2081', // one subscript
            'uni2082', // two subscript
            'uni2083', // three subscript
            'uni2084', // four subscript
            'uni2085', // five subscript
            'uni2086', // six subscript
            'uni2087', // seven subscript
            'uni2088', // eight subscript
            'uni2089', // nine subscript
            'uni208D', // parenleft subscript
            'uni208E', // parenright subscript
            'uni208A', // plus subscript
            'uni208B', // minus subscript
            'uni208C', // equal subscript
            //
            ...numSet('.inf'),
            'plus.inf',
            'minus.inf',
            'equal.inf',
            'parenleft.inf',
            'parenright.inf',
        ],
    },
    {
        title: 'Ordinals',
        orderedGlyphNames: [
            'ordfeminine',
            'ordmasculine',
            //
            'a.superior',
            'b.superior',
            'c.superior',
            'cordinal',
            'd.superior',
            'e.superior',
            'f.superior',
            'g.superior',
            'h.superior',
            'i.superior',
            'j.superior',
            'k.superior',
            'l.superior',
            'm.superior',
            'n.superior',
            'o.superior',
            'p.superior',
            'q.superior',
            'r.superior',
            's.superior',
            't.superior',
            'u.superior',
            'v.superior',
            'w.superior',
            'x.superior',
            'y.superior',
            'z.superior',
            'a.ord',
            'b.ord',
            'c.ord',
            'd.ord',
            'e.ord',
            'f.ord',
            'g.ord',
            'h.ord',
            'i.ord',
            'j.ord',
            'k.ord',
            'l.ord',
            'm.ord',
            'n.ord',
            'o.ord',
            'p.ord',
            'q.ord',
            'r.ord',
            's.ord',
            't.ord',
            'u.ord',
            'v.ord',
            'w.ord',
            'x.ord',
            'y.ord',
            'z.ord',
        ],
    },
    {
        title: 'Ordinal alternates',
        orderedGlyphNames: [
            'ordfeminine.school',
            'ordfeminine.1',
            'gsuperior.school',
            'ysuperior.school',
            'a.1.ord',
            'a.1ord',
            'a.ord.school',
            'g.1ord',
            'g.1.ord',
            'i.ord.round',
            'j.ord.round',
        ],
    },
    {
        title: 'Ligatures',
        orderedGlyphNames: [
            'T_T',
            'T_h',
            'c_y',
            'f_b',
            'f_f',
            'ff',
            'f_h',
            'fi',
            'uniFB01', // fi
            'f_j',
            'f_k',
            'fl',
            'uniFB02', // fl
            'f_t',
            'f_y',
            'f_f_b',
            'f_f_h',
            'ffi',
            'f_f_i',
            'f_f_j',
            'f_f_k',
            'ffl',
            'f_f_l',
            'f_f_t',
            'f_f_y',
            'f_t_t',
            'g_y',
            'r_y',
            't_t',
            't_t_y',
            't_y',
            'w_w_w',
            'z_z',
        ],
    },
    {
        title: 'Ligature accents',
        orderedGlyphNames: [
            'f_dotlessi',
            'f_idieresis',
            'f_adieresis',
            'f_f_adieresis',
            'f_f_dotlessi',
            'f_f_idieresis',
            'g_yacute',
        ],
    },
    {
        title: 'Ligature alternates',
        orderedGlyphNames: [
            'f_b.s',
            'ff.s',
            'f_h.1',
            'f_h.s',
            'f_h.s.1',
            'fi.s',
            'f_j.s',
            'f_k.s',
            'fl.s',
            'f_t.s',
            'f_f_b.s',
            'f_f.short',
            'f_f_short',
            'f_f_h.1',
            'f_f_h.s',
            'f_f_h.s.1',
            'ffi.s',
            'f_f_j.s',
            'f_f_k.s',
            'ffl.s',
            'f_f_t.s',
            'z_z.1',
        ],
    },
    {
        title: 'Discretionary ligatures',
        orderedGlyphNames: [
            'c_b',
            'c_h',
            'c_k',
            'c_l',
            'c_p',
            'c_t',
            'g_i',
            'g_j',
            'i_m',
            'i_n',
            'i_p',
            'i_r',
            'i_t',
            'i_t_t',
            'i_v',
            'i_w',
            'i_x',
            'i_y',
            's_b',
            's_h',
            's_k',
            's_l',
            's_p',
            'uniFB06', // s_t ligature
            's_t',
            's_t_y',
        ],
    },
    {
        title: 'Discretionary ligature accents',
        orderedGlyphNames: [
            'c_lslash',
            'c_tcaron',
            'c_tcommaaccent',
            'ccedilla_k',
            'ccedilla_l',
            's_lacute',
            's_lcaron',
            's_lslash',
            's_tcaron',
            's_tcommaaccent',
            'scedilla_k',
            'scedilla_l',
            'scedilla_p',
            'scedilla_t',
            'scommaaccent_p',
            'scommaaccent_t',
            'z_zcaron',
            'z_zdotaccent',
            'zcaron_z',
            'zcaron_zcaron',
            'zdotaccent_zdotaccent',
        ],
    },
    {
        title: 'Discretionary ligature alternates',
        orderedGlyphNames: [
            'c_k.1',
            'c_h.1',
            'f_k.1',
            'f_f_k.1',
            's_h.1',
            's_k.1',
        ],
    },
    {
        title: 'Discretionary ligature alternate accents',
        orderedGlyphNames: ['ccedilla_k.1', 'scedilla_k.1'],
    },
    {
        title: 'Punctuation & symbols',
        orderedGlyphNames: [
            'ampersand',
            'at',
            'uni2116', // numero
            'afii61352', // numero
            //
            'parenleft',
            'parenright',
            'bracketleft',
            'bracketright',
            'braceleft',
            'braceright',
            'anglebracketleft',
            'anglebracketright',
            //
            'slash',
            'bar',
            'doublebar',
            'brokenbar',
            'backslash',
            //
            'exclam',
            'exclamdown',
            'question',
            'questiondown',
            'interrobang',
            'interrobangdown',
            //
            'periodcentered',
            'bullet',
            //
            'uni00AD', // SOFT HYPHEN
            'hyphen',
            'uni2011', // NON-BREAKING HYPHEN
            'figuredash',
            'endash',
            'onethirdemdash',
            'threequarteremdash',
            'emdash',
            'uni2015', // HORIZONTAL BAR
            'asciitilde',
            'underscore',
            'underscoremonospace',
            //
            'period',
            'comma',
            'colon',
            'semicolon',
            'ellipsis',
            //
            'quotesingle',
            'quotedbl',
            'tripleprime',
            //
            'quoteleft',
            'quoteright',
            'quotedblleft',
            'quotedblright',
            'reversequoteleft',
            'reversequotedblleft',
            'quotesinglbase',
            'quotedblbase',
            //
            'guilsinglleft',
            'guilsinglright',
            'guillemotleft',
            'guillemotright',
            //
            'degree',
            'asciicircum',
            //
            'asterisk',
            'dagger',
            'daggerdbl',
            'section',
            'paragraph',
            //
            'currency',
            'copyright',
            'registered',
            'registered.superior',
            'trademark',
            'servicemark',
            'uni2117', // published
            'published',
            'u2117', // published
            'estimated',
            'afii61289', // litre
            //
            'partialdiff',
            'increment',
            'uni2206', // increment
            'Delta',
            'product',
            'summation',
            'ohm',
            'uni2126', // ohm
            'Omega',
            'uni03A9', // Omega
            'mu',
            'uni03BC', // mu
            'pi',
            //
            'radical',
            'infinity',
            'integral',
            'dotmath',
            'Numero',
            'numero',
        ],
    },
    {
        title: 'Punctuation & symbol alternates',
        orderedGlyphNames: [
            'ampersand.alt',
            ...alts('exclam', { add_dot_round: true }),
            ...alts('exclamdown', { add_dot_round: true }),
            ...alts('question', { add_dot_round: true }),
            ...alts('questiondown', { add_dot_round: true }),
            ...alts('periodcentered', { add_dot_round: true }),
            ...alts('period', { add_dot_round: true }),
            ...alts('comma', { add_dot_round: true }),
            ...alts('colon', { add_dot_round: true }),
            'colon.mid',
            'colon.round.mid',
            ...alts('semicolon', { add_dot_round: true }),
            ...alts('ellipsis', { add_dot_round: true }),
            ...alts('quoteleft', { add_dot_round: true }),
            ...alts('quoteright', { add_dot_round: true }),
            ...alts('quotedblleft', { add_dot_round: true }),
            ...alts('quotedblright', { add_dot_round: true }),
            ...alts('quotesinglbase', { add_dot_round: true }),
            ...alts('quotedblbase', { add_dot_round: true }),
        ],
    },
    {
        title: 'Punctuation & symbol capital forms',
        orderedGlyphNames: [
            'at.cap',
            'uni2116.cap', // numero
            'afii61352.cap', // numero
            //
            'parenleft.cap',
            'parenright.cap',
            'bracketleft.cap',
            'bracketright.cap',
            'braceleft.cap',
            'braceright.cap',
            'anglebracketleft.cap',
            'anglebracketright.cap',
            //
            'slash.cap',
            'bar.cap',
            'doublebar.cap',
            'brokenbar.cap',
            'backslash.cap',
            //
            'exclam.cap',
            'exclamdown.cap',
            'question.cap',
            'questiondown.cap',
            'interrobang.cap',
            'interrobangdown.cap',
            //
            'periodcentered.cap',
            'bullet.cap',
            //
            'uni00AD.cap', // SOFT HYPHEN
            'hyphen.cap',
            'uni2011.cap', // NON-BREAKING HYPHEN
            'figuredash.cap',
            'endash.cap',
            'onethirdemdash.cap',
            'threequarteremdash.cap',
            'emdash.cap',
            'uni2015.cap', // HORIZONTAL BAR
            'asciitilde.cap',
            'underscore.cap',
            'underscoremonospace.cap',
            //
            'guilsinglleft.cap',
            'guilsinglright.cap',
            'guillemotleft.cap',
            'guillemotright.cap',
            //
            'registered.cap',
        ],
    },
    {
        title: 'Punctuation & symbol capital alternates',
        orderedGlyphNames: [
            'exclamdown.alt.cap',
            'exclamdown.cap.round',
            'questiondown.alt.cap',
            'questiondown.cap.round',
            'periodcentered.alt.cap',
            'periodcentered.cap.round',
        ],
    },
    {
        title: 'Punctuation & symbol small capitals',
        orderedGlyphNames: [
            'ordfeminine.smcp',
            'ordmasculine.smcp',
            'cordinal.smcp',
            //
            'ampersand.smcp',
            'at.smcp',
            'uni2116.smcp', // numero
            'afii61352.smcp', // numero
            //
            'parenleft.smcp',
            'parenright.smcp',
            'bracketleft.smcp',
            'bracketright.smcp',
            'braceleft.smcp',
            'braceright.smcp',
            'anglebracketleft.smcp',
            'anglebracketright.smcp',
            //
            'slash.smcp',
            'bar.smcp',
            'doublebar.smcp',
            'brokenbar.smcp',
            'backslash.smcp',
            //
            'exclam.smcp',
            'exclamdown.smcp',
            'question.smcp',
            'questiondown.smcp',
            'interrobang.smcp',
            'interrobangdown.smcp',
            //
            'periodcentered.smcp',
            'bullet.smcp',
            //
            'uni00AD.smcp', // SOFT HYPHEN
            'hyphen.smcp',
            'uni2011.smcp', // NON-BREAKING HYPHEN
            'figuredash.smcp',
            'endash.smcp',
            'onethirdemdash.smcp',
            'threequarteremdash.smcp',
            'emdash.smcp',
            'uni2015.smcp', // HORIZONTAL BAR
            'asciitilde.smcp',
            'underscore.smcp',
            'underscoremonospace.smcp',
            //
            'guilsinglleft.smcp',
            'guilsinglright.smcp',
            'guillemotleft.smcp',
            'guillemotright.smcp',
            //
            'registered.smcp',
            //
            'parenleft.sc',
            'parenright.sc',
            'bracketleft.sc',
            'bracketright.sc',
            'braceleft.sc',
            'braceright.sc',
            'anglebracketleft.sc',
            'anglebracketright.sc',
            //
            'slash.sc',
            'bar.sc',
            'doublebar.sc',
            'brokenbar.sc',
            'backslash.sc',
            //
            'ampersand.sc',
            'exclam.sc',
            'exclamdown.sc',
            'question.sc',
            'questiondown.sc',
            'interrobang.sc',
            'interrobangdown.sc',
        ],
    },
    {
        title: 'Tabular punctuation',
        orderedGlyphNames: [
            'period.tab',
            'comma.tab',
            'colon.tab',
            'semicolon.tab',
        ],
    },
    {
        title: 'Tabular punctuation alternates',
        orderedGlyphNames: [
            'period.tab.round',
            'comma.tab.round',
            'colon.tab.round',
            'colon.mid.tab',
            'colon.round.mid.tab',
            'semicolon.tab.round',
        ],
    },
    {
        title: 'Prebuilt fractions',
        orderedGlyphNames: [
            'onequarter',
            'onehalf',
            'threequarters',
            'uni2153',
            'uni2154',
            'uni2155',
            'uni2156',
            'uni2157',
            'uni2158',
            'uni2159',
            'uni215A',
            'uni215B',
            'uni215C',
            'uni215D',
            'uni215E',
            'uni215F',
            'elevensixteenths',
            'fifteensixteenths',
            'fivesixteenths',
            'ninesixteenths',
            'onesixteenth',
            'sevensixteenths',
            'thirteensixteenths',
            'threesixteenths',
        ],
    },
    {
        title: 'Prebuilt fraction alternates',
        orderedGlyphNames: [
            'onequarter.alt',
            'onehalf.alt',
            'threequarters.alt',
            'uni2153.alt',
            'uni2154.alt',
            'uni215B.alt',
            'uni215C.alt',
        ],
    },
    {
        title: 'Arrows',
        orderedGlyphNames: [
            'arrowleft',
            'arrowup',
            'arrowright',
            'arrowdown',
            'arrowrightfeatheredwhite',
            'arrowleftright',
            'arrowupdown',
            'arrowNW',
            'uni2196', // arrowNW
            'arrowNE',
            'uni2197', // arrowNE
            'arrowSE',
            'uni2198', // arrowSE
            'arrowSW',
            'uni2199', // arrowSW
            'cloudLightning', // Cloud download
        ],
    },
    {
        title: 'Shapes',
        orderedGlyphNames: [
            'blacksquare',
            'whitesquare',
            'blacksmallsquare',
            'whitesmallsquare',
            'triagup',
            'triagrt',
            'triagdn',
            'triaglf',
            'lozenge',
            'uni25E2',
            'uni25E3',
            'uni25E4',
            'uni25E5',
            'blackcircle',
            'whitecircle',
            'circle',
        ],
    },
    {
        title: 'Playing card symbols',
        orderedGlyphNames: [
            'diamond',
            'heart',
            'spade',
            'club',
            'uni2662',
            'uni2661',
            'uni2664',
            'uni2667',
        ],
    },
    {
        title: 'Emo',
        orderedGlyphNames: [
            'emo_angry',
            'emo_frown',
            'emo_happy',
            'emo_sad',
            'emo_undecided',
            'emo_wink',
        ],
    },
];
