import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import FontFamilyInformation from '../components/FontFamilyInformation';
import FontFamilyFontStyles from '../components/FontFamilyFontStyles';
import _TitledSection from '../components/TitledSection';
import { useFontFamily } from '../components/PageContext';
import FontFamilyFontsInUse from '../components/FontFamilyFontsInUse';
import {
    VIEWPORT,
    FONT_FAMILY_MINIMUM_IN_USE_POSTS,
    FontSectionIds,
    FONT_SECTION_ROOT_MARGIN,
    FONT_SECTION_THRESHOLD,
} from '../settings/Global';
import { rememberVisitedPrereleaseFontFamilyId } from '../utils/visitedPrereleaseFonts';
import useSectionInView from '../hooks/useSectionInView';
import printStylesCount from '../utils/printStylesCount';
import useIsAllowedForUserTesting from '../hooks/useIsAllowedForUserTesting';
import { create404NotFoundUrl, createFontFamilyUrl } from '../utils/urlHelper';
import isBrowser from '../utils/isBrowser';
import NotableDetails from '../components/NotableDetails';
import FontHeroWithWrapper from '../components/FontHeroWithWrapper';
import FontFamilyOpenTypeFeatures from '../components/FontFamilyOpenTypeFeatures';
import { useGlobalState } from '../components/GlobalRuntimeState';
import useLocationHash from '../hooks/useLocationHash';
import Specimens from '../components/Specimens';
import GlyphInspector from '../components/GlyphInspector';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageDefault);
    padding-top: 0;
    margin: 0 var(--gridMarginGap);
`;

const Sections = styled.div`
    display: flex;
    flex-direction: column;
`;

const TitledSection = styled(_TitledSection)`
    padding-top: var(--spacing7);

    &:not(:first-child) {
        margin-top: 50vh;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            margin-top: 25vh;
        }
    }
`;

function FontFamilyPage(): React.ReactElement | null {
    const fontFamily = useFontFamily();
    const { svgHeading, name, campaign } = fontFamily;
    const [createIntersectionObserverRef] = useSectionInView();
    const [sectionInView] = useGlobalState('sectionInView');
    const [sectionInViewData] = useGlobalState('sectionInViewData');
    const hash = useLocationHash();
    const [specimensHaveLoaded, setSpecimensHaveLoaded] =
        React.useState<boolean>(false);

    const initialSectionInView = React.useMemo(() => {
        const hashParts = hash.split('/');
        const sectionHash = hashParts[0].slice(1);
        return Object.values(FontSectionIds).find(
            (value) => value === sectionHash,
        );
    }, []);

    // Scroll to section hash, on load
    React.useEffect(() => {
        if (!initialSectionInView || !specimensHaveLoaded) {
            return;
        }
        // if (initialSectionInView.toString() === hash.slice(1)) {
        //     // We're already there...
        //     return;
        // }
        const element = document.getElementById(initialSectionInView);
        if (!element) {
            return;
        }
        element.scrollIntoView({ behavior: 'instant' });
    }, [specimensHaveLoaded]);

    // Update URL hash when the section changes
    React.useEffect(() => {
        let newHash = sectionInView || '';
        if (sectionInView === FontSectionIds.OPEN_TYPE && sectionInViewData) {
            newHash = `${newHash}/${sectionInViewData['tag']}/${sectionInViewData['tab']}`;
        }
        if (newHash !== hash) {
            window.history.replaceState(
                null,
                '',
                createFontFamilyUrl(fontFamily.slug, newHash),
            );
        }
    }, [sectionInView, sectionInViewData]);

    const isAllowedForUserTesting = useIsAllowedForUserTesting(
        fontFamily.fontFamilyGroup.id,
    );
    if (isBrowser() && !isAllowedForUserTesting) {
        navigate(create404NotFoundUrl());
        return null;
    }

    const hasFontsInUsePages =
        fontFamily.featuredInUsePosts &&
        fontFamily.featuredInUsePosts.length >=
            FONT_FAMILY_MINIMUM_IN_USE_POSTS;

    // Remember that we've visited a pre-release font, if applicable.
    // For Collections, we don't need to do this, as Collections will
    // show all pre-release fonts on the Buy page, anyway.
    if (fontFamily.isPreRelease && !fontFamily.fontFamilyGroup.isCollection) {
        rememberVisitedPrereleaseFontFamilyId(fontFamily.id);
    }

    return (
        <>
            <FontHeroWithWrapper
                svgHeading={svgHeading}
                svgTitle={`${name} Family`}
                campaign={campaign}
            />
            <Container>
                <Sections>
                    <TitledSection
                        id={FontSectionIds.INFO}
                        ref={createIntersectionObserverRef(FontSectionIds.INFO)}
                        title="Information"
                    >
                        <FontFamilyInformation />
                    </TitledSection>
                    <TitledSection
                        id={FontSectionIds.STYLES}
                        ref={createIntersectionObserverRef(
                            FontSectionIds.STYLES,
                            FONT_SECTION_ROOT_MARGIN,
                            FONT_SECTION_THRESHOLD,
                        )}
                        title={
                            printStylesCount(fontFamily.fontStyleCount) ||
                            'Styles'
                        }
                    >
                        <FontFamilyFontStyles
                            fontFamily={fontFamily}
                            fontSizeMultiplier={fontFamily.fontSizeMultiplier}
                            withFragmentLinks
                        />
                    </TitledSection>
                    {fontFamily.notableDetails.length > 0 && (
                        <TitledSection
                            id={FontSectionIds.DETAILS}
                            ref={createIntersectionObserverRef(
                                FontSectionIds.DETAILS,
                                FONT_SECTION_ROOT_MARGIN,
                                FONT_SECTION_THRESHOLD,
                            )}
                            title="Details"
                            stacked
                        >
                            <NotableDetails />
                        </TitledSection>
                    )}
                    <TitledSection
                        id={FontSectionIds.SPECIMENS}
                        ref={createIntersectionObserverRef(
                            FontSectionIds.SPECIMENS,
                            FONT_SECTION_ROOT_MARGIN,
                            FONT_SECTION_THRESHOLD,
                        )}
                        title="Specimens"
                        stacked
                    >
                        <Specimens setIsLoaded={setSpecimensHaveLoaded} />
                    </TitledSection>
                    {fontFamily.openTypeFeatures.length > 0 && (
                        <TitledSection
                            id={FontSectionIds.OPEN_TYPE}
                            ref={createIntersectionObserverRef(
                                FontSectionIds.OPEN_TYPE,
                                FONT_SECTION_ROOT_MARGIN,
                                FONT_SECTION_THRESHOLD,
                            )}
                            title="OpenType features"
                            stacked
                        >
                            <FontFamilyOpenTypeFeatures />
                        </TitledSection>
                    )}
                    <TitledSection
                        id={FontSectionIds.GLYPHS}
                        ref={createIntersectionObserverRef(
                            FontSectionIds.GLYPHS,
                            FONT_SECTION_ROOT_MARGIN,
                            FONT_SECTION_THRESHOLD,
                        )}
                        title="Glyphs"
                        stacked
                    >
                        <GlyphInspector />
                    </TitledSection>
                    {hasFontsInUsePages && (
                        <TitledSection
                            id={FontSectionIds.IN_USE}
                            ref={createIntersectionObserverRef(
                                FontSectionIds.IN_USE,
                                FONT_SECTION_ROOT_MARGIN,
                                FONT_SECTION_THRESHOLD,
                            )}
                            title={`${fontFamily.name} in use`}
                            stacked
                        >
                            <FontFamilyFontsInUse />
                        </TitledSection>
                    )}
                </Sections>
            </Container>
        </>
    );
}

export default React.memo(FontFamilyPage);
