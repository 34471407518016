import { useFontFamily } from '../components/PageContext';
import { type FontFamiliesQuery } from '../gql/api-ssr';
import { useFeatureTagsInOrder } from '../components/TypeEditorFeatures';

export default function useFontFamilyOpenTypeFeaturesSorted(): FontFamiliesQuery['ssr']['fontFamilies'][number]['openTypeFeatures'] {
    const featureTagsInOrder = useFeatureTagsInOrder();
    return useFontFamily().openTypeFeatures.sort(
        (a, b) =>
            featureTagsInOrder.indexOf(a.tag) -
            featureTagsInOrder.indexOf(b.tag),
    );
}
