import React from 'react';
import { useFontFamily } from './PageContext';
import FontIntroduction from './FontIntroduction';
import FontFamilyAccordion from './FontFamilyAccordion';
import FontInformation from './FontInformation';

function FontFamilyInformation(): React.ReactElement {
    const fontFamily = useFontFamily();
    const { fontFamilyGroup } = fontFamily;

    return (
        <FontInformation
            intro={
                fontFamilyGroup && fontFamilyGroup.informationIntro ? (
                    <FontIntroduction
                        informationIntro={fontFamilyGroup.informationIntro}
                        blogPostSlug={
                            fontFamilyGroup.designInformationPage &&
                            fontFamilyGroup.designInformationPage.slug
                        }
                    />
                ) : null
            }
            content={<FontFamilyAccordion />}
        />
    );
}

export default FontFamilyInformation;
