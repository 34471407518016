import React from 'react';
import styled, { css } from 'styled-components';
import { HeadingSmall } from './Heading';
import { GLYPH_INSPECTOR_BREAKPOINT, VIEWPORT } from '../settings/Global';

interface ContainerProps {
    $stacked?: boolean;
}

const Container = styled.div<ContainerProps>`
    display: grid;
    width: 100%;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-gap: var(--spacing5) var(--gridColumnGap);
    ${({ $stacked }): ReturnType<typeof css> | null => {
        /*
        Massive performance issues in Firefox and Safari for Specimen sections
        with a lot of text content... So only apply baseline alignment to sections
        very sparingly, where needed. Stacked sections inherently don't need this.
         */
        return !$stacked
            ? css`
                  align-items: baseline;
              `
            : null;
    }};

    /* we are stacking rows on top, no gap needed */
    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        grid-row-gap: 0;
    }
`;

const Title = styled(HeadingSmall)<{
    $stacked?: boolean;
    $hideTitleOnTablet?: boolean;
}>`
    ${({ $stacked }): ReturnType<typeof css> =>
        $stacked
            ? css`
                  grid-column: 1 / -1;
              `
            : css`
                  grid-column: 1 / span 2;
              `};

    margin-top: calc(-1 * var(--scrollOffset));
    padding-top: var(--scrollOffset);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
        margin-bottom: var(--spacing4);
    }

    ${({ $hideTitleOnTablet }): ReturnType<typeof css> | null =>
        $hideTitleOnTablet
            ? css`
                  @media screen and (max-width: ${VIEWPORT.TABLET}px) {
                      display: none;
                  }
              `
            : null};
`;

const Content = styled.div<ContainerProps>`
    position: relative;
    min-width: 0; /* needed for Firefox to stop overflow */
    ${({ $stacked }): ReturnType<typeof css> =>
        $stacked
            ? css`
                  grid-column: 1 / -1;
              `
            : css`
                  grid-column: 3 / -1;
                  display: grid;

                  --columnCount: calc(var(--gridColumnCount) - 2);

                  @media screen and (max-width: ${VIEWPORT.TABLET}px) {
                      grid-column: 1 / -1;

                      --columnCount: var(--gridColumnCount);
                  }

                  grid-template-columns: repeat(
                      var(--columnCount),
                      minmax(0, 1fr)
                  );
                  grid-column-gap: var(--gridColumnGap);

                  /*
                  Massive performance issues in Firefox and Safari for Specimen sections
                  with a lot of text content... So only apply baseline alignment to sections
                  very sparingly, where needed. Stacked sections inherently don't need this.
                  */
                  align-items: baseline;
              `};

    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        margin-top: 0;
    }
`;

const TitledSection = React.forwardRef(
    (
        {
            title,
            subtitle,
            stacked,
            hideTitleOnTablet,
            children,
            ...rest
        }: React.PropsWithChildren<
            React.HTMLAttributes<HTMLDivElement> &
                ContainerProps & {
                    title: string;
                    subtitle?: React.ReactNode;
                    stacked?: boolean;
                    hideTitleOnTablet?: boolean;
                }
        >,
        forwardedRef: React.Ref<HTMLDivElement>,
    ): React.ReactElement => {
        return (
            <Container ref={forwardedRef} {...rest} $stacked={stacked}>
                {title && (
                    <Title
                        $stacked={stacked}
                        $hideTitleOnTablet={hideTitleOnTablet}
                    >
                        {title}
                        {subtitle}
                    </Title>
                )}
                <Content $stacked={stacked}>{children}</Content>
            </Container>
        );
    },
);

export default React.memo(TitledSection);
