/**
 * E2E testing settings
 *
 * This file is also included by the E2E test code. Please ensure that
 * it doesn't have any library imports as that would cause dependency
 * issues for the E2E project!
 */
export enum TEST_ID {
    // Cart
    CART_WRAP = 'cartWrap',
    CART_CHECKOUT_BUTTON = 'cartCheckout',
    CART_SHARE_BUTTON = 'cartShare',
    CART_QUOTE_BUTTON = 'cartQuote',
    CART_ITEM_REMOVE_BUTTON = 'cartRemove',
    CART_INVENTORY = 'cartInventory',
    CART_INVENTORY_MOBILE = 'cartInventoryMobile',
    CART_FONT = 'cartFont',
    CART_ITEM_PRICE = 'cartItemPrice',
    CART_ROW = 'cartRow',
    CART_ROW_TOTAL = 'cartRowTotal',
    CART_ROW_PACKAGE_DISCOUNT = 'cartRowPackageDiscount',
    CART_ROW_OTHER_DISCOUNT = 'cartRowDiscount',
    CART_TOTALS = 'cartTotals',
    CART_TOTALS_AMOUNT = 'cartTotalsAmount',

    // Buy page
    BUY_FONTS_WRAP = 'buyFontsWrap',
    BUY_FONT_TITLE = 'buyFontTitle',
    BUY_FONT_BUTTON = 'buyFontButton',
    BUY_FONT_CHILDREN = 'buyFontChildren',
    BUY_FONT_CONTAINER = 'buyFontContainer',

    // Blog cards
    BLOG_CARD = 'blogCard',
    BLOG_CARD_LINK = 'blogCardLink',

    // Blog post
    BLOG_POST_TITLE = 'blogPostTitle',

    // Licence selector title
    LICENCE_SELECTOR_LABEL = 'licenceSelectLabel',

    // Font family
    FONT_FAMILY_HERO = 'fontFamilyHero',
    FONT_FAMILY_STYLES = 'fontFamilyStyles',
    FONT_FAMILY_STYLE_TITLE = 'fontFamilyStyleTitle',

    // Specimen editor
    TYPE_EDITOR = 'typeEditor',
    TYPE_EDITOR_STYLE = 'typeEditorStyle',
    TYPE_EDITOR_HUD = 'typeEditorHud',
    TYPE_EDITOR_STYLE_SELECT = 'typeEditorHudStyleSelect',

    // Glyph inspector
    GLYPH_INSPECTOR_RENDER_MODE = 'renderMode',

    // OpenType features
    OPEN_TYPE_FEATURES_TOGGLE = 'openTypeToggle',

    // Nav
    NAV_ITEM_MAIN_MENU = 'navItemMainMenu',
    NAV_ITEM_FONTS = 'navItemFonts',
    NAV_ITEM_CART = 'navItemCart',

    // Forms
    FIELD_ERROR = 'fieldError',
    FIELD_CONTAINER = 'fieldContainer',
    SELECT_LABEL = 'selectLabel',
    SELECT_CONTENT = 'selectContent',

    // Test fonts
    TEST_FONTS_FORM = 'testFontsForm',
    TEST_FONTS_DL_LINK = 'testFontsDlLink',

    // Checkout
    STRIPE_ERROR = 'stripeError',
    CHECKOUT_PROCESSING_OVERLAY = 'checkoutProcessingOverlay',

    // Font menu
    FONT_MENU_COLLECTION = 'fontMenuCollection',
    FONT_MENU_FAMILY = 'fontMenuFamily',

    // Style guide
    STYLE_GUIDE_ICONS = 'styleGuideIcons',

    // Account page
    ACCOUNT_HEADING = 'accountHeading',
    ACCOUNT_USER_DETAILS = 'accountUserDetails',
    ACCOUNT_ORDER_HISTORY = 'accountOrderHistory',

    // Modal
    MODAL_CONTAINER = 'modalContainer',
}
