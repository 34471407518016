/**
 * All external hyperlinks are defined here, so that they can be periodically checked
 * to ensure they remain alive (not 404).
 */

export type Hyperlink = {
    title: string;
    url: string;
    allowedResponseStatuses?: number[];
};

/*
General links
 */
export const STRIPE_HYPERLINK: Hyperlink = {
    title: 'Stripe',
    url: 'https://stripe.com',
};
export const CAMPAIGN_MONITOR_HYPERLINK: Hyperlink = {
    title: 'Campaign Monitor',
    url: 'https://www.campaignmonitor.com/',
};
export const CAMPAIGN_MONITOR_PRIVACY_POLICY_HYPERLINK: Hyperlink = {
    title: 'Campaign Monitor Privacy Policy',
    url: 'https://www.campaignmonitor.com/policies/#privacy-policy',
};
export const GOOGLE_ANALYTICS_HYPERLINK: Hyperlink = {
    title: 'Google Analytics',
    url: 'https://marketingplatform.google.com/about/analytics/',
};
export const GOOGLE_PRIVACY_POLICY_HYPERLINK: Hyperlink = {
    title: 'Google Privacy Policy',
    url: 'https://policies.google.com/privacy',
};
export const NZ_PRIVACY_ACT_HYPERLINK: Hyperlink = {
    title: 'New Zealand Privacy Act 2020 - Codes of practice',
    url: 'https://www.privacy.org.nz/privacy-act-2020/codes-of-practice/',
};

/*
Social media links
 */
export const KLIM_FACEBOOK_HYPERLINK: Hyperlink = {
    title: 'Facebook',
    url: 'https://www.facebook.com/klimtypefoundry/',
};
export const KLIM_TUMBLR_HYPERLINK: Hyperlink = {
    title: 'Tumblr',
    url: 'https://klim-type-foundry.tumblr.com/',
};
export const KLIM_INSTAGRAM_HYPERLINK: Hyperlink = {
    title: 'Instagram',
    url: 'https://www.instagram.com/klim_type_foundry/',
};
export const KLIM_PINTEREST_HYPERLINK: Hyperlink = {
    title: 'Pinterest',
    url: 'https://nz.pinterest.com/klimtype/',
};
export const KLIM_TWITTER_HYPERLINK: Hyperlink = {
    title: 'Twitter',
    url: 'https://twitter.com/klimtypefoundry',
    // Twitter gives a "forbidden" response and requires you to log in
    allowedResponseStatuses: [403],
};
export const KLIM_VIMEO_HYPERLINK: Hyperlink = {
    title: 'Vimeo',
    url: 'https://vimeo.com/user20273759',
};
export const KLIM_FLICKR_HYPERLINK: Hyperlink = {
    title: 'Flickr',
    url: 'https://www.flickr.com/photos/20994543@N04/',
};

/*
OpenType app support links, in groups
 */
export const OPEN_TYPE_APP_SUPPORT_ADOBE_HYPERLINKS: Hyperlink[] = [
    {
        title: 'Adobe Illustrator',
        url: 'https://helpx.adobe.com/illustrator/using/special-characters.html',
    },
    {
        title: 'Adobe InDesign',
        url: 'https://helpx.adobe.com/indesign/using/using-fonts.html',
    },
    {
        title: 'Adobe Photoshop',
        url: 'https://helpx.adobe.com/photoshop/using/glyph-panel.html',
    },
];
export const OPEN_TYPE_APP_SUPPORT_AFFINITY_HYPERLINKS: Hyperlink[] = [
    {
        title: 'Affinity Designer',
        url: 'https://affinity.help/designer2/en-US.lproj/index.html?page=pages/Text/opentype_fonts.html',
    },
    {
        title: 'Affinity Photo',
        url: 'https://affinity.help/photo2/en-US.lproj/index.html?page=pages/Text/opentype_fonts.html',
    },
    {
        title: 'Affinity Publisher',
        url: 'https://affinity.help/publisher2/en-US.lproj/index.html?page=pages/Text/opentype_fonts.html',
    },
];
export const OPEN_TYPE_APP_SUPPORT_OTHER_HYPERLINKS: Hyperlink[] = [
    {
        title: 'Apple Pages',
        url: 'https://pimpmytype.com/otf-in-pages/',
    },
    {
        title: 'Figma',
        url: 'https://help.figma.com/hc/en-us/articles/4913951097367-Use-OpenType-features',
    },
    {
        title: 'Quark',
        url: 'https://www.quark.com/documentation/quarkxpress/2022/english/A%20Guide%20to%20QuarkXPress%202022/_5_3719690306931965E7_6.html#_7_219874868213914E7_6_21',
    },
    {
        title: 'Sketch',
        url: 'https://www.sketch.com/docs/designing/text/',
    },
];

export const OPEN_TYPE_APP_SUPPORT_MICROSOFT_HYPERLINKS: Hyperlink[] = [
    {
        title: 'Microsoft Word & Outlook',
        url: 'https://support.microsoft.com/en-us/office/font-advanced-settings-b5b42f97-eda9-406a-b1f1-295cf2954c3d',
    },
    {
        title: 'Microsoft Publisher',
        url: 'https://support.microsoft.com/en-us/office/use-typographic-styles-to-increase-the-impact-of-your-publication-10e14096-452f-4d3b-9938-1d537572a377',
    },
];

export const OPENTYPE_CSS_PROPERTY_DOCS_HYPERLINK: Hyperlink = {
    title: 'CSS property',
    url: 'https://developer.mozilla.org/en-US/docs/Web/CSS/font-feature-settings',
};

export const OPENTYPE_CSS_DESCRIPTOR_DOCS_HYPERLINK: Hyperlink = {
    title: 'CSS descriptor',
    url: 'https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-feature-settings',
};

// For link checking purposes, export a comprehensive list of all links
const ALL_HYPERLINKS: Hyperlink[] = [
    STRIPE_HYPERLINK,
    CAMPAIGN_MONITOR_HYPERLINK,
    CAMPAIGN_MONITOR_PRIVACY_POLICY_HYPERLINK,
    GOOGLE_ANALYTICS_HYPERLINK,
    GOOGLE_PRIVACY_POLICY_HYPERLINK,
    NZ_PRIVACY_ACT_HYPERLINK,
    KLIM_TUMBLR_HYPERLINK,
    KLIM_FLICKR_HYPERLINK,
    KLIM_FACEBOOK_HYPERLINK,
    KLIM_TWITTER_HYPERLINK,
    KLIM_INSTAGRAM_HYPERLINK,
    KLIM_PINTEREST_HYPERLINK,
    KLIM_VIMEO_HYPERLINK,
    OPENTYPE_CSS_PROPERTY_DOCS_HYPERLINK,
    OPENTYPE_CSS_DESCRIPTOR_DOCS_HYPERLINK,
]
    .concat(OPEN_TYPE_APP_SUPPORT_ADOBE_HYPERLINKS)
    .concat(OPEN_TYPE_APP_SUPPORT_AFFINITY_HYPERLINKS)
    .concat(OPEN_TYPE_APP_SUPPORT_OTHER_HYPERLINKS)
    .concat(OPEN_TYPE_APP_SUPPORT_MICROSOFT_HYPERLINKS);

export default ALL_HYPERLINKS;
