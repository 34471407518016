import { LicenceTypeId } from '../gql/api-public';

export enum COLOR {
    WHITE = '#FFFFFF',
    BLACK = '#000000',
    RED = '#E4001C',
    MUELLER = '#FF3E00',
    GREY = '#808080',
    GREEN = '#4EBC32',
}

// The basic product type on the server side is a desktop font.
export const LICENCE_TYPE_ID_BASE = LicenceTypeId.DESKTOP;

export enum VIEWPORT {
    DESKTOP_LARGE = 2000,
    DESKTOP = 1680,
    NETBOOK = 1366,
    NETBOOK_SMALL = 1220,
    TABLET_LARGE = 1024,
    TABLET = 768,
    MOBILE = 414,
}

export const GLYPH_INSPECTOR_BREAKPOINT = VIEWPORT.TABLET;
export const MOBILE_GLYPH_BUTTON_WIDTH = 50;
export const MOBILE_MAX_GLYPH_ROWS = 4;
export const DESKTOP_GLYPHS_PER_ROW = 10;

export enum FONT_FAMILY {
    SOEHNE = 'SOEHNE',
    SOEHNE_MONO = 'SOEHNE_MONO',
    SOEHNE_IKON = 'SOEHNE_IKON',
    MARTINA_PLANTIJN = 'MARTINA_PLANTIJN',
    CALIBRE2 = 'CALIBRE2',
}

// aspectRatio based on "Klim-blog-index-Geograph.jpg" being 2000x1334
export enum BLOG_IMAGE_ASPECT_RATIO {
    WIDTH = 2000,
    HEIGHT = 1334,
}

/*
 * Takes advantage of the native behaviour of enums: to be incremental
 * integers. In other words, we don't need to set values explicitly,
 * only change the order of items in the enum in order to affect their
 * relative z-index.
 */
export enum ZINDEX {
    ZERO,
    ONE,
    TYPE_EDITOR_TOOLBAR,
    GLYPH_INSPECTOR_HOVER,
    FONT_SELECTOR_INPUT_DEFAULT,
    FONT_SELECTOR_INPUT_CHECKED,
    FONT_SELECTOR_INPUT_ACTIVE,
    GLYPH_INSPECTOR_GRADIENT,
    NAVBAR,
    OVER_NAVBAR,
    GRADIENT_OVERLAY,
    SITE_OVERLAY_BLOCK_INTERACTION,
    SITE_OVERLAY_COLOR,
    MODAL,
    CART_SUMMARY,
    MENU_OVERLAY,
    PROCESSING_OVERLAY,
    SITE_OVERLAY_BLOCK_INTERACTION_UBER_ALLES,
    SITE_OVERLAY_COLOR_UBER_ALLES,
    MODAL_UBER_ALLES,
    GRID_OVERLAY,
    ERROR_OVERLAY,
    ADMIN_PANEL,
}

export enum LINE_HEIGHT {
    HEADING_1 = 0.98,
    HEADING_2 = 1.25,
    HEADING_3 = 1.1,
    BODY_1 = 1.3333,
    BODY_2 = 1.5,
    BODY_3 = 0.75,
    BODY_4 = 1.8,
    BODY_5 = 1.1667,
    BODY_6 = 1.1875,
    BODY_BLOG = 1.4,
}

// Glyph settings
export enum GLYPH {
    BEZIER_POINT_RADIUS = 3,
}

export enum GLYPH_RENDER_MODE {
    FILL = 'FILL',
    OUTLINE = 'OUTLINE',
}

// Used to size <iframe>s. Any number may be used, but will probably be
// either "1:1", "4:3", or "16:9" (most popular). In most cases the videos
// will provide custom aspect ratios.
export const DEFAULT_VIDEO_ASPECT_RATIO = '16:9';

// Google Analytics settings
export const GTAG_AFFILIATION = 'Klim';
export const GTAG_KLIM_BRAND = 'Klim';
export const GA_EVENT_NAME_DOWNLOAD_TEST_FONTS = 'download_test_fonts';

export enum SpecimenType {
    BIG,
    MEDIUM,
    SMALL,
}

export const DEFAULT_SPECIMEN_MIN_FONT_SIZE_PX = 18;

export const MEDIUM_SPECIMEN_MOBILE_FONT_SIZE_MULTIPLIER = 1.3;

/*
 * The minimum number of "in use" posts required to display an "in use"
 * section.
 */
export const FONT_FAMILY_MINIMUM_IN_USE_POSTS = 6;

export const TEST_SITE_CHECKOUT_DISABLED_MESSAGE =
    'Checkout has been disabled on the test site.';

export const IN_USE_ALL_CATEGORIES_TAG_SLUG = 'all';

export const DEFAULT_TAX_ID_LABEL = 'Tax number';

export const CART_GRADIENT_HEIGHT = 30;
export const CART_IN_PAGE_BREAKPOINT = VIEWPORT.TABLET;
export const MARGIN_SMALL = 10;
export const MARGIN_BIG = 20;
export const LOZENGE_HEIGHT = 28;
export const LOZENGE_SPACING = 5;
export const BUTTON_AND_FIELD_HEIGHT = 36;
export const NAVBAR_VERTICAL_MARGIN = MARGIN_SMALL;

export const FOOTNOTE_CLASSNAME = 'footnote';
export const FOOTNOTE_BACK_CLASSNAME = `${FOOTNOTE_CLASSNAME}-back`;
export const CART_CONTAINER_ID = 'cart';
export const BUY_PAGE_LICENCES_ID = 'buy-licences';
export const BUY_PAGE_FONTS_ID = 'buy-fonts';

// CSS counter names
export const COUNTER_SIDENOTE = 'sidenote';
export const COUNTER_FOOTNOTE = 'footnote';

export enum FontSectionIds {
    INFO = 'information',
    STYLES = 'styles',
    DETAILS = 'details',
    SPECIMENS = 'specimens',
    GLYPHS = 'glyphs',
    OPEN_TYPE = 'open-type',
    IN_USE = 'in-use',
    FAMILIES = 'families',
}

export enum FontOpenTypeTabNames {
    EXAMPLE = 'example',
    ALL_GLYPHS = 'all-glyphs',
    CSS = 'css',
    APP_SUPPORT = 'app-support',
}

// Add a negative top and bottom margin to the viewport so that sections do not
// trigger until they are further into the visible screen area
export const FONT_SECTION_ROOT_MARGIN = '0px 0px -30% 0px';
export const FONT_SECTION_THRESHOLD = [0];
