import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { useOpenTypeFeatureState } from './FontFamilyOpenTypeFeaturesContext';
import getOpenTypeFeatureLabel from '../utils/getOpenTypeFeatureLabel';
import getOpenTypeFeatureDescription from '../utils/getOpenTypeFeatureDescription';
import { useOpenTypeFeatureMetaData } from '../hooks/useConfig';
import { Select, SelectItem, Button as SelectButton } from './Select';
import useFontFamilyOpenTypeFeaturesSorted from '../hooks/useFontFamilyOpenTypeFeaturesSorted';
import { LOZENGE_SPACING, VIEWPORT } from '../settings/Global';
import _Switch from './Switch';
import { TEST_ID } from '../settings/E2e';
import _Paragraph from './Paragraph';
import { useFontFamily, useStaticFontMetricOffsets } from './PageContext';
import printAmpersandSeries from '../utils/printAmpersandSeries';
import { type FontMetricOffsets } from '../utils/fontMetricsCalculator';
import { Div as Lozenge } from './Lozenge';
import notDuplicate from '../utils/notDuplicate';

const Switch = styled(_Switch)`
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin-left: 0;
    }
`;

const Tag = styled(Lozenge)<{ $isOn: boolean }>`
    font-family: var(--fontFamilySoehneMono);
    max-width: fit-content;

    ${({ $isOn }): ReturnType<typeof css> | null =>
        $isOn
            ? css`
                  --lozengeColor: var(--backgroundColor);
                  --lozengeBackgroundColor: var(--foregroundColor);
              `
            : null}
`;

const Wrap = styled.div`
    grid-column: 1 / span 3;

    @media screen and (max-width: ${VIEWPORT.DESKTOP}px) {
        grid-column: 1 / span 4;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / -1;
    }
`;

const Paragraph = styled(_Paragraph)<{ $hasDescription: boolean }>`
    ${({ $hasDescription }): ReturnType<typeof css> | null =>
        $hasDescription
            ? css`
                  margin-top: calc(
                      var(--lineHeightBody2) * var(--fontSizeFixed2)
                  );
              `
            : null};
`;

const Controls = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: ${LOZENGE_SPACING}px;
    align-content: stretch;
    margin-bottom: var(--spacing5);

    --lozengeWidth: auto;
    --lozengeBackgroundColor: var(--menuBackgroundColor);

    ${SelectButton} {
        flex-grow: 1;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-bottom: var(--spacing4);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / -1;
    }
`;

const DescriptionWrap = styled.div<{
    $metricOffsets: FontMetricOffsets;
}>`
    width: 100%;

    ${({ $metricOffsets }): ReturnType<typeof css> | null =>
        $metricOffsets
            ? css`
                  @media screen and (min-width: ${VIEWPORT.MOBILE + 1}px) {
                      margin-top: ${-$metricOffsets.ascent}em;
                      margin-bottom: ${$metricOffsets.descent}em;
                  }
              `
            : null}
`;

function FontFamilyOpenTypeFeaturesList(): React.ReactElement {
    const fontFamily = useFontFamily();
    const fontFamilyOpenTypeFeatures = useFontFamilyOpenTypeFeaturesSorted();
    const openTypeFeatureMetaData = useOpenTypeFeatureMetaData();
    const [openTypeFeatureState, setOpenTypeFeatureState] =
        useOpenTypeFeatureState();
    const staticFontMetrics = useStaticFontMetricOffsets();
    const { description, label, fontStylesString } = React.useMemo(() => {
        const fontStylesString: ReactNode[] =
            openTypeFeatureState.openTypeFeature.fontStyles.length !==
            fontFamily.fontStyles.length
                ? printAmpersandSeries(
                      openTypeFeatureState.openTypeFeature.fontStyles.map(
                          (fontStyle) => fontStyle.name,
                      ),
                  )
                : ['all font styles'];
        return {
            description: getOpenTypeFeatureDescription(
                openTypeFeatureState.openTypeFeature,
                openTypeFeatureMetaData,
            ),
            label: getOpenTypeFeatureLabel(
                openTypeFeatureState.openTypeFeature.tag,
                openTypeFeatureMetaData,
                undefined,
                openTypeFeatureState.openTypeFeature,
            ),
            fontStylesString,
        };
    }, [openTypeFeatureState.openTypeFeature]);

    return (
        <Wrap>
            <Controls>
                <Select
                    value={openTypeFeatureState.openTypeFeature.tag}
                    onValueChange={(value: string) => {
                        const newFeature = fontFamilyOpenTypeFeatures.find(
                            (feat) => feat.tag === value,
                        );
                        if (!newFeature) {
                            return;
                        }
                        setOpenTypeFeatureState((state) => {
                            return {
                                ...state,
                                openTypeFeature: newFeature,
                            };
                        });
                    }}
                >
                    {fontFamilyOpenTypeFeatures.map((feat) => (
                        <SelectItem value={feat.tag} key={feat.tag}>
                            {getOpenTypeFeatureLabel(
                                feat.tag,
                                openTypeFeatureMetaData,
                                fontFamilyOpenTypeFeatures,
                            )}
                        </SelectItem>
                    ))}
                </Select>
                {[
                    openTypeFeatureState.openTypeFeature.tag,
                    ...openTypeFeatureState.openTypeFeature.secondaryTags,
                ]
                    .filter(notDuplicate)
                    .map((tag) => (
                        <Tag $isOn={openTypeFeatureState.isOn} key={tag}>
                            {tag}
                        </Tag>
                    ))}
                <Switch
                    data-cy={TEST_ID.OPEN_TYPE_FEATURES_TOGGLE}
                    checked={openTypeFeatureState.isOn}
                    onCheckedChange={(checked) => {
                        setOpenTypeFeatureState((state) => {
                            return {
                                ...state,
                                isOn: checked,
                            };
                        });
                    }}
                    title={`${label}: ${openTypeFeatureState.isOn ? 'on' : 'off'}`}
                    onLabel="On"
                    offLabel="Off"
                    onOffLabelBreakpoint={VIEWPORT.MOBILE}
                    holdSpaceForOnOffLabel
                />
            </Controls>
            <DescriptionWrap
                $metricOffsets={staticFontMetrics.SOEHNE_BUCH.BODY_2}
            >
                {description ? (
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                ) : null}
                <Paragraph $hasDescription={Boolean(description)}>
                    Available in {fontStylesString}.
                </Paragraph>
            </DescriptionWrap>
        </Wrap>
    );
}

export default FontFamilyOpenTypeFeaturesList;
