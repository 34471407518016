import React from 'react';
import styled, { css } from 'styled-components';

import { useOpenTypeFeatureState } from './FontFamilyOpenTypeFeaturesContext';
import Tabs, {
    TabContent,
    TabsList as _TabsList,
    TabTrigger as _TabTrigger,
} from './Tabs';
import getOpenTypeFeatureExample from '../utils/getOpenTypeFeatureExample';
import { useOpenTypeFeatureMetaData } from '../hooks/useConfig';
import getOpenTypeFeatureAllGlyphs from '../utils/getOpenTypeFeatureAllGlyphs';
import openTypeTagsToFontFeatureSettings from '../utils/openTypeTagsToFontFeatureSettings';
import type { CMS_SSR_CssRenderInfo } from '../gql/api-ssr';
import getCssFromRenderInfo from '../utils/getCssFromRenderInfo';
import { useFontFamily, useStaticFontMetricOffsets } from './PageContext';
import disableFontFeatureSetting from '../utils/disableFontFeatureSetting';
import {
    FontSectionIds,
    LOZENGE_HEIGHT,
    FontOpenTypeTabNames,
    VIEWPORT,
} from '../settings/Global';
import {
    type FontMetricOffsets,
    FontMetricsCalculator,
} from '../utils/fontMetricsCalculator';
import _Paragraph, { ParagraphMarginStyles } from './Paragraph';
import { A } from './InlineUnderlinedLink';
import getOpenTypeFeatureLabel from '../utils/getOpenTypeFeatureLabel';
import { LOZENGE_BORDER_RADIUS } from './Lozenge';
import FontFamilyOpenTypeFeaturesAppSupport from './FontFamilyOpenTypeFeaturesAppSupport';
import { useGlobalState } from './GlobalRuntimeState';
import useLocationHash from '../hooks/useLocationHash';
import {
    OPENTYPE_CSS_DESCRIPTOR_DOCS_HYPERLINK,
    OPENTYPE_CSS_PROPERTY_DOCS_HYPERLINK,
} from '../settings/Hyperlinks';

const EXAMPLE_LINE_HEIGHT = 1;
const EXAMPLE_SMALL_LINE_HEIGHT = 'normal';

const CssExample = styled.pre<{
    $metricOffsets: FontMetricOffsets | undefined;
}>`
    ${ParagraphMarginStyles};

    font-family: var(--fontFamilySoehneMono);
    height: auto;
    width: fit-content;
    line-height: var(--lineHeightBody2);
    padding: 8px 16px;
    border: 1px solid var(--foregroundColorMix7);
    border-radius: ${LOZENGE_BORDER_RADIUS}px;

    ${({ $metricOffsets }): ReturnType<typeof css> | null =>
        $metricOffsets
            ? css`
                  @media screen and (min-width: ${VIEWPORT.MOBILE + 1}px) {
                      margin-top: calc(${-$metricOffsets.ascent}em - 9px);
                  }
              `
            : null}
`;

const Paragraph = styled(_Paragraph)<{
    $metricOffsets?: FontMetricOffsets;
}>`
    ${({ $metricOffsets }): ReturnType<typeof css> | null =>
        $metricOffsets
            ? css`
                  @media screen and (min-width: ${VIEWPORT.MOBILE + 1}px) {
                      margin-top: ${-$metricOffsets.ascent}em;
                      margin-bottom: ${$metricOffsets.descent}em;
                  }
              `
            : null};
`;

const InlineCode = styled.code`
    font-family: var(--fontFamilySoehneMono);
`;

const Wrap = styled.div`
    grid-column: 5 / -1;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
    }
`;

const TabsList = styled(_TabsList)`
    display: flex;
    width: 100%;
    height: ${LOZENGE_HEIGHT}px;
    align-content: stretch;
    margin-bottom: var(--spacing5);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-bottom: var(--spacing4);
    }
`;

const TabTrigger = styled(_TabTrigger)`
    flex-grow: 1;
`;

const TabTriggerSeparator = styled.div<{ $hide: boolean }>`
    width: 1px;
    height: ${LOZENGE_HEIGHT - 8}px;
    background-color: var(--foregroundColorMix5);

    ${({ $hide }): ReturnType<typeof css> | null =>
        $hide
            ? css`
                  visibility: hidden;
              `
            : null};
`;

const Example = styled.div<{
    $isOn: boolean;
    $cssRenderInfo: CMS_SSR_CssRenderInfo;
    $fontSizeMultiplier: number;
    $metricOffsets: FontMetricOffsets | undefined;
    $small?: boolean;
}>`
    ${({ $fontSizeMultiplier, $small }): ReturnType<typeof css> => css`
        font-size: calc(
            var(${$small ? '--fontSizeFluid7' : '--fontSizeFluid10'}) *
                ${$fontSizeMultiplier}
        );
    `};

    line-height: ${({ $small }): string | number =>
        $small ? EXAMPLE_SMALL_LINE_HEIGHT : EXAMPLE_LINE_HEIGHT};
    color: var(--foregroundColorMix5);

    ${({ $cssRenderInfo }): ReturnType<typeof css> =>
        getCssFromRenderInfo($cssRenderInfo)};

    mark {
        color: var(--foregroundColorMix5);
        background-color: transparent;
    }

    ${({ $isOn }): ReturnType<typeof css> | null =>
        $isOn
            ? css`
                  mark {
                      color: var(--foregroundColor);
                  }
              `
            : null}

    ${({ $metricOffsets }): ReturnType<typeof css> | null =>
        $metricOffsets
            ? css`
                  @media screen and (min-width: ${VIEWPORT.MOBILE + 1}px) {
                      margin-top: ${-$metricOffsets.ascent}em;
                      margin-bottom: ${$metricOffsets.descent}em;
                  }
              `
            : null};

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        ${({ $fontSizeMultiplier, $small }): ReturnType<typeof css> => css`
            font-size: calc(
                var(${$small ? '--fontSizeFluid5' : '--fontSizeFluid8'}) *
                    ${$fontSizeMultiplier}
            );
        `};
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        ${({ $fontSizeMultiplier, $small }): ReturnType<typeof css> => css`
            font-size: calc(
                var(${$small ? '--fontSizeFluid5' : '--fontSizeFluid6'}) *
                    ${$fontSizeMultiplier}
            );
        `};
    }
`;

function FontFamilyOpenTypeFeaturesView(): React.ReactElement {
    const fontFamily = useFontFamily();
    const [openTypeFeatureState] = useOpenTypeFeatureState();
    const openTypeFeatureMetaData = useOpenTypeFeatureMetaData();
    const staticFontMetrics = useStaticFontMetricOffsets();
    const [, setSectionInViewData] = useGlobalState('sectionInViewData');
    const locationHash = useLocationHash();

    const initialTab = React.useMemo(() => {
        if (
            !locationHash ||
            !locationHash.startsWith(`#${FontSectionIds.OPEN_TYPE}`)
        ) {
            return;
        }
        const hashParts = locationHash.split('/');
        if (
            hashParts.length > 2 &&
            Object.values(FontOpenTypeTabNames).includes(
                hashParts[2] as FontOpenTypeTabNames,
            )
        ) {
            return hashParts[2] as FontOpenTypeTabNames;
        }
    }, []);

    const [activeTab, setActiveTab] = React.useState<FontOpenTypeTabNames>(
        initialTab || FontOpenTypeTabNames.EXAMPLE,
    );

    React.useEffect(() => {
        return (): void => setSectionInViewData(undefined);
    }, []);
    const [hoverTab, setHoverTab] = React.useState<FontOpenTypeTabNames | null>(
        null,
    );
    React.useEffect(() => {
        setSectionInViewData({
            tag: openTypeFeatureState.openTypeFeature.tag,
            tab: activeTab,
        });
    }, [activeTab, openTypeFeatureState]);
    const {
        exampleHtmlOn,
        exampleHtmlOff,
        allGlyphsHtmlOn,
        allGlyphsHtmlOff,
        exampleCss,
        fontStyle,
        label,
    } = React.useMemo(() => {
        const fontStyle = openTypeFeatureState.openTypeFeature.fontStyles.find(
            (fs) => fs.id === fontFamily.mainFontStyle.id,
        )
            ? fontFamily.mainFontStyle
            : openTypeFeatureState.openTypeFeature.fontStyles[0];
        const allGlyphsHtmlOn = getOpenTypeFeatureAllGlyphs(
            openTypeFeatureState.openTypeFeature,
            openTypeFeatureMetaData,
        );
        const allGlyphsHtmlOff: string | undefined =
            allGlyphsHtmlOn !== undefined
                ? disableFontFeatureSetting(
                      allGlyphsHtmlOn,
                      openTypeFeatureState.openTypeFeature.tag,
                  )
                : undefined;
        const exampleHtmlOn = getOpenTypeFeatureExample(
            openTypeFeatureState.openTypeFeature,
            openTypeFeatureMetaData,
        );
        const exampleHtmlOff: string | undefined =
            exampleHtmlOn !== undefined
                ? disableFontFeatureSetting(
                      exampleHtmlOn,
                      openTypeFeatureState.openTypeFeature.tag,
                  )
                : undefined;
        const exampleCssTags = [
            openTypeFeatureState.openTypeFeature.tag,
            ...openTypeFeatureState.openTypeFeature.secondaryTags,
        ];
        return {
            exampleHtmlOn,
            exampleHtmlOff,
            allGlyphsHtmlOn,
            allGlyphsHtmlOff,
            exampleCss: `font-feature-settings: ${openTypeTagsToFontFeatureSettings(exampleCssTags)};`,
            fontStyle,
            label: getOpenTypeFeatureLabel(
                openTypeFeatureState.openTypeFeature.tag,
                openTypeFeatureMetaData,
                undefined,
                openTypeFeatureState.openTypeFeature,
            ),
        };
    }, [openTypeFeatureState.openTypeFeature]);

    const [exampleFontMetrics, exampleSmallFontMetrics] = React.useMemo(() => {
        if (!fontStyle.otfFiles) {
            return [undefined, undefined];
        }
        return [
            new FontMetricsCalculator({
                lineHeight: EXAMPLE_LINE_HEIGHT,
                unitsPerEm: fontStyle.otfFiles.metrics.unitsPerEmHead,
                capHeight: fontStyle.otfFiles.metrics.capHeightOs2,
                ascentCalc: fontStyle.otfFiles.metrics.ascentCalc,
                ascent: fontStyle.otfFiles.metrics.ascentHhea,
                descentCalc: fontStyle.otfFiles.metrics.descentCalc,
                descent: fontStyle.otfFiles.metrics.descentHhea,
                lineGap: fontStyle.otfFiles.metrics.lineGapHhea,
            }).calculate(),
            new FontMetricsCalculator({
                lineHeight: EXAMPLE_SMALL_LINE_HEIGHT,
                unitsPerEm: fontStyle.otfFiles.metrics.unitsPerEmHead,
                capHeight: fontStyle.otfFiles.metrics.capHeightOs2,
                ascentCalc: fontStyle.otfFiles.metrics.ascentCalc,
                ascent: fontStyle.otfFiles.metrics.ascentHhea,
                descentCalc: fontStyle.otfFiles.metrics.descentCalc,
                descent: fontStyle.otfFiles.metrics.descentHhea,
                lineGap: fontStyle.otfFiles.metrics.lineGapHhea,
            }).calculate(),
        ];
    }, [fontStyle]);

    return (
        <Wrap>
            <Tabs
                defaultValue={initialTab}
                value={activeTab}
                onValueChange={(value) =>
                    setActiveTab(value as FontOpenTypeTabNames)
                }
            >
                <TabsList>
                    <TabTrigger
                        value={FontOpenTypeTabNames.EXAMPLE}
                        onMouseOver={() =>
                            setHoverTab(FontOpenTypeTabNames.EXAMPLE)
                        }
                        onMouseOut={() =>
                            setHoverTab((state) =>
                                state === FontOpenTypeTabNames.EXAMPLE
                                    ? null
                                    : state,
                            )
                        }
                    >
                        Example
                    </TabTrigger>
                    <TabTriggerSeparator
                        $hide={
                            [
                                FontOpenTypeTabNames.EXAMPLE,
                                FontOpenTypeTabNames.ALL_GLYPHS,
                            ].filter((value) =>
                                [activeTab, hoverTab].includes(value),
                            ).length > 0
                        }
                    />
                    <TabTrigger
                        value={FontOpenTypeTabNames.ALL_GLYPHS}
                        onMouseOver={() =>
                            setHoverTab(FontOpenTypeTabNames.ALL_GLYPHS)
                        }
                        onMouseOut={() =>
                            setHoverTab((state) =>
                                state === FontOpenTypeTabNames.ALL_GLYPHS
                                    ? null
                                    : state,
                            )
                        }
                    >
                        All glyphs
                    </TabTrigger>
                    <TabTriggerSeparator
                        $hide={
                            [
                                FontOpenTypeTabNames.CSS,
                                FontOpenTypeTabNames.ALL_GLYPHS,
                            ].filter((value) =>
                                [activeTab, hoverTab].includes(value),
                            ).length > 0
                        }
                    />
                    <TabTrigger
                        value={FontOpenTypeTabNames.CSS}
                        onMouseOver={() =>
                            setHoverTab(FontOpenTypeTabNames.CSS)
                        }
                        onMouseOut={() =>
                            setHoverTab((state) =>
                                state === FontOpenTypeTabNames.CSS
                                    ? null
                                    : state,
                            )
                        }
                    >
                        CSS
                    </TabTrigger>
                    <TabTriggerSeparator
                        $hide={
                            [
                                FontOpenTypeTabNames.CSS,
                                FontOpenTypeTabNames.APP_SUPPORT,
                            ].filter((value) =>
                                [activeTab, hoverTab].includes(value),
                            ).length > 0
                        }
                    />
                    <TabTrigger
                        value={FontOpenTypeTabNames.APP_SUPPORT}
                        onMouseOver={() =>
                            setHoverTab(FontOpenTypeTabNames.APP_SUPPORT)
                        }
                        onMouseOut={() =>
                            setHoverTab((state) =>
                                state === FontOpenTypeTabNames.APP_SUPPORT
                                    ? null
                                    : state,
                            )
                        }
                    >
                        App support
                    </TabTrigger>
                </TabsList>
                <TabContent value={FontOpenTypeTabNames.EXAMPLE}>
                    {exampleHtmlOn && exampleHtmlOff ? (
                        <Example
                            $isOn={openTypeFeatureState.isOn}
                            $cssRenderInfo={fontStyle.cssRenderInfo}
                            $fontSizeMultiplier={fontFamily.fontSizeMultiplier}
                            $metricOffsets={exampleFontMetrics}
                            dangerouslySetInnerHTML={{
                                __html: openTypeFeatureState.isOn
                                    ? exampleHtmlOn
                                    : exampleHtmlOff,
                            }}
                        />
                    ) : (
                        <Paragraph
                            $metricOffsets={
                                staticFontMetrics.SOEHNE_BUCH.BODY_2
                            }
                        >
                            Example not available
                        </Paragraph>
                    )}
                </TabContent>
                <TabContent value={FontOpenTypeTabNames.ALL_GLYPHS}>
                    {allGlyphsHtmlOn && allGlyphsHtmlOff ? (
                        <Example
                            $isOn={openTypeFeatureState.isOn}
                            $cssRenderInfo={fontStyle.cssRenderInfo}
                            $fontSizeMultiplier={fontFamily.fontSizeMultiplier}
                            $metricOffsets={exampleSmallFontMetrics}
                            $small
                            dangerouslySetInnerHTML={{
                                __html: openTypeFeatureState.isOn
                                    ? allGlyphsHtmlOn
                                    : allGlyphsHtmlOff,
                            }}
                        />
                    ) : (
                        <Paragraph
                            $metricOffsets={
                                staticFontMetrics.SOEHNE_BUCH.BODY_2
                            }
                        >
                            All glyphs not available
                        </Paragraph>
                    )}
                </TabContent>
                <TabContent value={FontOpenTypeTabNames.CSS}>
                    <CssExample
                        $metricOffsets={
                            staticFontMetrics.SOEHNE_MONO_BUCH.BODY_2
                        }
                    >
                        {exampleCss}
                    </CssExample>
                    <Paragraph>
                        The above <InlineCode>font-feature-settings</InlineCode>{' '}
                        <A href={OPENTYPE_CSS_PROPERTY_DOCS_HYPERLINK.url}>
                            CSS property
                        </A>{' '}
                        or{' '}
                        <A href={OPENTYPE_CSS_DESCRIPTOR_DOCS_HYPERLINK.url}>
                            CSS descriptor
                        </A>{' '}
                        can be used to enable{' '}
                        {label ? label.toLowerCase() : 'this feature'} in your
                        webpage.
                    </Paragraph>
                </TabContent>
                <TabContent value={FontOpenTypeTabNames.APP_SUPPORT}>
                    <FontFamilyOpenTypeFeaturesAppSupport />
                </TabContent>
            </Tabs>
        </Wrap>
    );
}

export default FontFamilyOpenTypeFeaturesView;
