import React from 'react';
import styled, { css } from 'styled-components';
import { Switch as SwitchPrimitive } from 'radix-ui';
import { LOZENGE_SPACING } from '../settings/Global';
import isBrowser from '../utils/isBrowser';

const ANIM_SPEED = 50;
const ANIM_EASE = 'ease-out';

const Label = styled.label`
    display: inline-flex;
    flex-direction: row;
    gap: ${LOZENGE_SPACING}px;
    align-items: center;
    cursor: pointer;
`;

const Root = styled(SwitchPrimitive.Root)`
    width: 48px;
    height: 28px;
    background-color: var(--menuBackgroundColor);
    transition: background-color ${ANIM_SPEED}ms ${ANIM_EASE};
    will-change: background-color;
    border-radius: 9999px;
    position: relative;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

    &[data-state='checked'] {
        background-color: var(--foregroundColor);
    }
`;

const Thumb = styled(SwitchPrimitive.Thumb)`
    display: block;
    width: 22px;
    height: 22px;
    background-color: var(--foregroundColor);
    transition:
        background-color ${ANIM_SPEED}ms ${ANIM_EASE},
        transform ${ANIM_SPEED}ms ${ANIM_EASE};
    will-change: transform, background-color;
    border-radius: 9999px;
    transform: translateX(3px);

    &[data-state='checked'] {
        transform: translateX(23px);
        background-color: var(--backgroundColor);
    }
`;

const OnOffLabel = styled.span<{ $breakpoint?: number; $minWidth?: string }>`
    ${({ $minWidth }): ReturnType<typeof css> | null =>
        $minWidth
            ? css`
                  min-width: ${$minWidth};
              `
            : null};
    ${({ $breakpoint }): ReturnType<typeof css> | null =>
        $breakpoint !== undefined
            ? css`
                  @media screen and (max-width: ${$breakpoint}px) {
                      display: none;
                  }
              `
            : null};
`;

interface SwitchProps extends SwitchPrimitive.SwitchProps {
    label?: string;
    onLabel?: string;
    offLabel?: string;
    onOffLabelBreakpoint?: number;
    holdSpaceForOnOffLabel?: boolean;
}

const Switch = React.forwardRef(
    (
        props: SwitchProps,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ): React.ReactElement => {
        const {
            label,
            title,
            onLabel,
            offLabel,
            onOffLabelBreakpoint,
            holdSpaceForOnOffLabel,
            ...rest
        } = props;
        const showOnOffLabel = onLabel !== undefined && offLabel !== undefined;

        const onOffLabelMinWidth = React.useMemo(() => {
            if (!showOnOffLabel || !holdSpaceForOnOffLabel || !isBrowser()) {
                return;
            }
            // Calculate the longest width, to hold space for the longest label
            let longestWidth = 0;
            const el = document.createElement('div');
            el.style.visibility = 'hidden';
            el.style.display = 'inline-block';
            document.body.appendChild(el);
            [onLabel, offLabel].forEach((labelText) => {
                el.textContent = labelText;
                if (el.clientWidth > longestWidth) {
                    longestWidth = el.clientWidth;
                }
            });
            el.remove();
            if (longestWidth > 0) {
                return `${longestWidth}px`;
            }
        }, [onLabel, offLabel, holdSpaceForOnOffLabel, showOnOffLabel]);

        return (
            <Label title={title}>
                {label?.trim()}
                <Root ref={forwardedRef} {...rest}>
                    <Thumb />
                </Root>
                {showOnOffLabel ? (
                    <OnOffLabel
                        $breakpoint={onOffLabelBreakpoint}
                        $minWidth={onOffLabelMinWidth}
                    >
                        {props.checked ? onLabel : offLabel}
                    </OnOffLabel>
                ) : null}
            </Label>
        );
    },
);
Switch.displayName = 'Switch';

export default Switch;
